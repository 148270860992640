import React, { useState, useMemo } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { MessageSquare } from "lucide-react";
import MeetingSummaryModal from "../organisms/MeetingSummaryModal";
import { formatDate } from "@/contexts/MeetingsContext";

const LastCallsSection = ({ guestMeetings }) => {
  const [selectedMeeting, setSelectedMeeting] = useState(null);

  const meetingData = useMemo(() => {
    if (!guestMeetings || guestMeetings.length === 0) {
      return [];
    }
    return guestMeetings.map((meeting) => ({
      id: meeting.meeting_id,
      hostName: meeting.host?.name || "Unknown Host",
      hostUserId: meeting.host_user_id,
      date: formatDate(meeting.event_start_time),
      feedback: meeting.feedback?.[0]?.content || "No feedback available",
      rating: meeting.feedback?.[0]?.rating,
      summary: meeting.summary || "No summary available",
    }));
  }, [guestMeetings]);

  const handleOpenModal = (meeting) => {
    setSelectedMeeting(meeting);
  };

  const handleCloseModal = () => {
    setSelectedMeeting(null);
  };

  return (
    <Card className="h-full">
      <CardContent className="p-4">
        <h3 className="text-lg font-semibold mb-4">Last Calls:</h3>
        {meetingData.length > 0 ? (
          meetingData.map((meeting, index) => (
            <div
              key={index}
              className="mb-4 p-2 bg-white rounded shadow text-sm relative"
            >
              <div className="flex justify-between items-start">
                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">{meeting.hostName}</p>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleOpenModal(meeting)}
                      className="p-1"
                    >
                      <MessageSquare className="h-4 w-4" />
                    </Button>
                  </div>
                  <p className="text-gray-600">{meeting.date}</p>
                  <p className="text-blue-600">{meeting.feedback}</p>
                  {meeting.rating && (
                    <p className="text-yellow-600">
                      Rating: {meeting.rating}/5
                    </p>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm">No previous calls with this guest</p>
        )}
      </CardContent>
      {selectedMeeting && (
        <MeetingSummaryModal
          isOpen={!!selectedMeeting}
          onClose={handleCloseModal}
          hostName={selectedMeeting.hostName}
          summary={selectedMeeting.summary}
          meetingId={selectedMeeting.id}
          hostUserId={selectedMeeting.hostUserId}
        />
      )}
    </Card>
  );
};

export default LastCallsSection;
