import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { useMeetingsContext, formatDate } from "../contexts/MeetingsContext";
import HostsSkeleton from "../components/molecules/HostsSkeleton";
import UpcomingGuestContent from "../components/organisms/UpcomingGuestContent";
import CompletedGuestContent from "../components/organisms/CompletedGuestContent";
import NoShowGuestContent from "../components/organisms/NoShowGuestContent";
import NoMeetingsMessage from "../components/molecules/NoMeetingsMessage";
import { useMatches } from "../integrations/supabase";

const fadeVariants = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
};

const Hosts = () => {
  const {
    isLoading,
    error,
    currentMeeting,
    scheduledMeetings,
    completedMeetings,
    noShowMeetings,
  } = useMeetingsContext();
  const { useGetMatches } = useMatches();
  const { data: guestMatches } = useGetMatches(currentMeeting?.guest_user_id);

  if (isLoading) {
    return <HostsSkeleton />;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const noMeetings =
    scheduledMeetings.length === 0 &&
    completedMeetings.length === 0 &&
    noShowMeetings.length === 0;

  if (noMeetings) {
    return <NoMeetingsMessage />;
  }

  if (!currentMeeting) {
    return <NoMeetingsMessage />;
  }

  const guestProfile = currentMeeting.guest;
  const isCompletedMeeting = currentMeeting.meeting_status === "done";
  const isNoShowMeeting = currentMeeting.meeting_status.includes("noshow");

  const commonProps = {
    name: guestProfile?.name || "Guest Name Not Available",
    location: guestProfile?.location || "Location Not Available",
    careerStage: guestProfile?.career_stage || "Career Stage Not Available",
    imageUrl: guestProfile?.image_url || "",
    loopDate: formatDate(currentMeeting.event_start_time),
    meetingUrl: currentMeeting.meeting_url || "#",
    briefing: currentMeeting.briefing || "Briefing not available",
    rescheduleOrCancelUrl: currentMeeting.rescheduleorcancel_url || "",
    meetingId: currentMeeting.meeting_id,
  };

  return (
    <div className="flex flex-col h-full max-h-screen overflow-hidden">
      <h1 className="text-3xl font-bold text-blue-500 mb-4">
        Guest Information
      </h1>
      <div className="flex-grow overflow-auto">
        <AnimatePresence mode="wait">
          <motion.div
            key={currentMeeting.meeting_id}
            variants={fadeVariants}
            initial="initial"
            animate="animate"
            exit="exit"
            transition={{ duration: 0.3 }}
          >
            {isCompletedMeeting ? (
              <CompletedGuestContent
                {...commonProps}
                matches={guestMatches}
                guestUserId={currentMeeting.guest_user_id}
                interests={guestProfile?.interests || []}
                keySkills={guestProfile?.key_skills || []}
                businessGoals={guestProfile?.business_goals || []}
              />
            ) : isNoShowMeeting ? (
              <NoShowGuestContent {...commonProps} />
            ) : (
              <UpcomingGuestContent {...commonProps} />
            )}
          </motion.div>
        </AnimatePresence>
      </div>
    </div>
  );
};

export default Hosts;
