import React from 'react';
import RatingButton from '../atoms/RatingButton';

const RatingSection = ({ rating, setRating }) => {
  const ratingOptions = [
    { value: 1, label: 'Terrible' },
    { value: 2, label: 'Bad' },
    { value: 3, label: 'Okay' },
    { value: 4, label: 'Good' },
    { value: 5, label: 'Amazing' },
  ];

  return (
    <div className="flex justify-between mb-6 gap-1 sm:gap-2">
      {ratingOptions.map((option) => (
        <RatingButton
          key={option.value}
          value={option.value}
          label={option.label}
          rating={rating}
          setRating={setRating}
        />
      ))}
    </div>
  );
};

export default RatingSection;