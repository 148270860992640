import React, { useState, useCallback } from "react";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { TableCell, TableRow } from "@/components/ui/table";
import { X, Check } from "lucide-react";

const AddItemRow = React.memo(({ title, onCancel, onApprove }) => {
  const [newItemValue, setNewItemValue] = useState("");

  const handleChange = useCallback((e) => {
    setNewItemValue(e.target.value);
  }, []);

  const handleApprove = useCallback(() => {
    onApprove(newItemValue);
    setNewItemValue("");
  }, [newItemValue, onApprove]);

  return (
    <TableRow>
      <TableCell colSpan="2">
        <Input
          value={newItemValue}
          onChange={handleChange}
          placeholder={`New item`}
        />
      </TableCell>
      <TableCell className="text-right">
        <div className="flex justify-end space-x-2">
          <Button variant="ghost" size="icon" onClick={onCancel}>
            <X className="h-4 w-4" />
          </Button>
          <Button variant="ghost" size="icon" onClick={handleApprove}>
            <Check className="h-4 w-4" />
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
});

export default AddItemRow;
