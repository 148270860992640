import React, { Suspense, useEffect, useState } from "react";
import { Toaster } from "@/components/ui/sonner";
import { TooltipProvider } from "@/components/ui/tooltip";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Layout from "./components/templates/Layout";
import Index from "./pages/Index";
import Meetings from "./pages/Meetings";
import Login from "./pages/Login";
import ResetPassword from "./pages/ResetPassword";
import Hosts from "./pages/Hosts";
import ProtectedRoute from "./components/ProtectedRoute";
import { useSupabaseAuth } from "./integrations/supabase/auth";
import { MeetingProvider } from "./contexts/MeetingContext";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import Cookies from "js-cookie";
import Navbar from "./components/organisms/Navbar";
import LoadingSpinner from "./components/atoms/LoadingSpinner";

const OnboardingPopup = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[800px] z-50">
        <div className="aspect-video">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/xXFtce4jYtI"
            title="Onboarding Video"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </DialogContent>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-20 backdrop-blur-sm z-40"
          onClick={onClose}
        />
      )}
    </Dialog>
  );
};

// Define the routes configuration
const routes = [
  { path: "/login", component: Login, isProtected: false },
  { path: "/reset_password", component: ResetPassword, isProtected: false },
  { path: "/", component: Index, isProtected: true },
  { path: "/meetings", component: Meetings, isProtected: true },
  { path: "/hosts", component: Hosts, isProtected: true },
];

const AnimatedRoutes = () => {
  const location = useLocation();
  const { session, loading } = useSupabaseAuth();

  if (loading) return <LoadingSpinner />;

  return (
    <AnimatePresence mode="wait">
      <motion.div
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Routes location={location}>
          {routes.map(({ path, component: Component, isProtected }) => (
            <Route
              key={path}
              path={path}
              element={
                path === "/login" && session ? (
                  <Navigate to="/" replace />
                ) : isProtected ? (
                  <ProtectedRoute>
                    <Layout>
                      <Component />
                    </Layout>
                  </ProtectedRoute>
                ) : (
                  <Component />
                )
              }
            />
          ))}
        </Routes>
      </motion.div>
    </AnimatePresence>
  );
};

const App = () => {
  const [showOnboarding, setShowOnboarding] = useState(false);
  const { session } = useSupabaseAuth();
  const location = useLocation();

  useEffect(() => {
    const hasWatchedOnboarding = Cookies.get("watched_onboarding") === "true";
    const userEmail = session?.user?.email;
    const excludedEmails = [
      "admin@libralab.ai",
      "marius.santiago.wilsch@gmail.com",
    ];

    if (
      !hasWatchedOnboarding &&
      userEmail &&
      !excludedEmails.includes(userEmail) &&
      location.pathname !== "/reset_password" //? What about /login?
    ) {
      setShowOnboarding(true);
    }
  }, [session, location.pathname]);

  const handleCloseOnboarding = () => {
    setShowOnboarding(false);
    Cookies.set("watched_onboarding", "true", { expires: 365 });
  };

  return (
    <MeetingProvider>
      <TooltipProvider>
        <Toaster position="top-right" />
        <Navbar />
        <AnimatedRoutes />
        <OnboardingPopup
          isOpen={showOnboarding}
          onClose={handleCloseOnboarding}
        />
      </TooltipProvider>
    </MeetingProvider>
  );
};

export default App;
