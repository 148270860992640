import React from "react";
import { Badge } from "../ui/badge";
import { AlertDialog } from "../ui/alert-dialog";
import { toast } from "sonner";
import { useMatches } from "@/integrations/supabase/hooks/useMatches";
import ConnectionBadgeContent, {
  BADGE_STATES,
} from "../atoms/ConnectionBadgeContent";
import LocationSelectionModal from "./LocationSelectionModal";
import FollowConfirmationModal from "./FollowConfirmationModal";
import { cn } from "@/lib/utils";
import { useSupabaseAuth } from "@/integrations/supabase";
import ConnectionRequestModal from "./ConnectionRequestModal";

const ConnectionBadge = ({
  userName,
  meetLocation,
  connection_status,
  matchId,
  isReceiver,
  onLocationUpdate,
}) => {
  const { session } = useSupabaseAuth();
  const { useUpdateMatch } = useMatches();
  const updateMatch = useUpdateMatch();

  const [isLocationModalOpen, setIsLocationModalOpen] = React.useState(false);
  const [isFollowModalOpen, setIsFollowModalOpen] = React.useState(false);
  const [isResponseModalOpen, setIsResponseModalOpen] = React.useState(false);
  const [badgeState, setBadgeState] = React.useState(() => {
    if (connection_status === "pending") return BADGE_STATES.PENDING;
    if (connection_status === "accepted") return BADGE_STATES.FOLLOWING;
    return meetLocation ? BADGE_STATES.FOLLOW : BADGE_STATES.INITIAL;
  });
  const isReciever = isReceiver;

  const handleLocationSelect = async (location) => {
    try {
      onLocationUpdate(location);

      await updateMatch.mutateAsync({
        match_id: matchId,
        updatedMatch: {
          meet_location: location,
        },
      });

      setBadgeState(BADGE_STATES.FOLLOW);
      setIsLocationModalOpen(false);
      toast.success(`Location set: Met ${userName} via ${location}`);
    } catch (error) {
      onLocationUpdate(null);
      toast.error("Failed to update location");
    }
  };

  const handleFollowConfirm = async () => {
    try {
      await updateMatch.mutateAsync({
        match_id: matchId,
        updatedMatch: {
          connection_status: "pending",
        },
      });

      setBadgeState(BADGE_STATES.PENDING);
      setIsFollowModalOpen(false);
      toast.success(`Connection request sent to ${userName}`);
    } catch (error) {
      toast.error("Failed to send connection request");
    }
  };

  const handleBadgeClick = (event) => {
    event.stopPropagation();
    if (isReciever && connection_status === "pending") {
      setIsResponseModalOpen(true);
    } else if (badgeState === BADGE_STATES.INITIAL) {
      setIsLocationModalOpen(true);
    } else if (badgeState === BADGE_STATES.FOLLOW) {
      setIsFollowModalOpen(true);
    }
  };

  const handleAccept = async () => {
    try {
      await updateMatch.mutateAsync({
        match_id: matchId,
        updatedMatch: {
          connection_status: "accepted",
          match_confirmed_at: new Date().toISOString(),
        },
      });

      setBadgeState(BADGE_STATES.FOLLOWING);
      setIsResponseModalOpen(false);
      toast.success(`Connection with ${userName} accepted!`);
    } catch (error) {
      toast.error("Failed to accept connection");
    }
  };

  const handleDeny = async () => {
    try {
      await updateMatch.mutateAsync({
        match_id: matchId,
        updatedMatch: {
          connection_status: "rejected",
        },
      });

      setIsResponseModalOpen(false);
      toast.success(`Connection with ${userName} declined`);
    } catch (error) {
      toast.error("Failed to decline connection");
    }
  };

  return (
    <>
      <div className="w-full sm:w-auto">
        <Badge
          variant={badgeState === BADGE_STATES.FOLLOWING ? "success" : "outline"}
          className={cn(
            "cursor-pointer w-full h-[24px] flex items-center justify-center sm:w-auto",
            badgeState === BADGE_STATES.FOLLOWING
              ? "cursor-default bg-green-500"
              : "hover:bg-secondary"
          )}
          onClick={
            badgeState !== BADGE_STATES.FOLLOWING ? handleBadgeClick : undefined
          }
        >
          <ConnectionBadgeContent
            state={badgeState}
            connection_status={connection_status}
            isReceiver={isReciever}
          />
        </Badge>
      </div>

      <AlertDialog
        open={isLocationModalOpen}
        onOpenChange={setIsLocationModalOpen}
      >
        <LocationSelectionModal
          userName={userName}
          onSelect={handleLocationSelect}
          onClose={(e) => {
            e?.stopPropagation();
            setIsLocationModalOpen(false);
          }}
        />
      </AlertDialog>

      <AlertDialog open={isFollowModalOpen} onOpenChange={setIsFollowModalOpen}>
        <FollowConfirmationModal
          userName={userName}
          onConfirm={handleFollowConfirm}
          onClose={(e) => {
            e?.stopPropagation();
            setIsFollowModalOpen(false);
          }}
        />
      </AlertDialog>

      <AlertDialog
        open={isResponseModalOpen}
        onOpenChange={setIsResponseModalOpen}
      >
        <ConnectionRequestModal
          userName={userName}
          onAccept={(e) => {
            e?.stopPropagation();
            handleAccept();
          }}
          onDeny={(e) => {
            e?.stopPropagation();
            handleDeny();
          }}
        />
      </AlertDialog>
    </>
  );
};

export default ConnectionBadge;