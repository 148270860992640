import React from "react";
import { Badge } from "@/components/ui/badge";
import { Handshake } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";

const CareerStageBadge = ({ careerStage }) => (
  <div className="w-full sm:w-auto flex items-center gap-2">
    <Badge
      variant="outline"
      className="w-full h-[24px] flex items-center justify-center sm:w-auto"
    >
      <span className="w-full text-center">{careerStage}</span>
    </Badge>
  </div>
);

export default CareerStageBadge;
