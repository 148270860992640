import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const HostAvatar = ({ name, imageUrl }) => (
  <Avatar className="h-32 w-32"> {/* Increased from h-20 w-20 to h-32 w-32 */}
    <AvatarImage src={imageUrl} alt={name || 'Host'} />
    <AvatarFallback>{name ? name.charAt(0) : '?'}</AvatarFallback>
  </Avatar>
);

export default HostAvatar;
