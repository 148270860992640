import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { motion } from "framer-motion";
import { LogOut, Home } from "lucide-react";
import { Button } from "@/components/ui/button";

const HostsIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="w-4 h-4">
    <path fill="currentColor" d="M50,20.45c-17,0-30.9,13.8-30.9,30.9,0,7.7,2.8,14.7,7.5,20.1,1.4-2,3.1-3.8,5.1-5.2-3.3-4.1-5.3-9.3-5.3-15,0-13,10.6-23.7,23.6-23.7s23.7,10.6,23.7,23.7c0,5.7-2,10.9-5.3,15,1.9,1.4,3.7,3.2,5,5.2,4.7-5.4,7.6-12.4,7.6-20.1-.1-17-13.9-30.9-31-30.9Z"/>
    <path fill="currentColor" d="M50,3.85C23.8,3.85,2.5,25.15,2.5,51.35c0,15.7,7.7,29.7,19.5,38.4l.8-8.2c0-.1,0-.3.1-.5-8.1-7.3-13.2-18-13.2-29.7,0-22.2,18-40.3,40.3-40.3s40.3,18.1,40.3,40.3c0,11.8-5.1,22.3-13.1,29.7,0,.2,0,.5.1.7l.8,8c11.8-8.6,19.5-22.6,19.5-38.3-.1-26.3-21.4-47.6-47.6-47.6Z"/>
    <path fill="currentColor" d="M69,96.05H31c-1.3,0-2.2-1.1-2.1-2.3l1.1-11.3c.9-7.5,7.2-13.1,14.8-13.1h10.5c7.5,0,13.9,5.6,14.8,13.1l1.1,11.2c0,1.3-.9,2.4-2.2,2.4Z"/>
    <path fill="currentColor" d="M64,51.35c0-7.7-6.3-14-14-14s-14,6.3-14,14,6.3,14,14,14,14-6.2,14-14Z"/>
  </svg>
);

const NavButtonGroup = ({ onSignOut, isHost }) => {
  const location = useLocation();
  const isHostsRoute = location.pathname === '/hosts';

  if (!isHost) {
    return (
      <Button
        onClick={onSignOut}
        className="bg-purple-600 hover:bg-purple-700 text-white w-[80px] h-[32px] text-xs"
      >
        <LogOut className="w-4 h-4 mr-1" />
        Sign Out
      </Button>
    );
  }

  return (
    <div className="flex items-center space-x-6">
      <div className="relative w-[100px] h-[32px] bg-gray-200 rounded-full p-1.5">
        <motion.div
          className="absolute top-1.5 left-1.5 w-[44px] h-[20px] bg-white rounded-full shadow-md flex items-center justify-center"
          initial={false}
          animate={{ x: isHostsRoute ? 46 : 0 }}
          transition={{ type: "spring", stiffness: 500, damping: 30 }}
        >
          {isHostsRoute ? <HostsIcon /> : <Home className="w-4 h-4" />}
        </motion.div>
        <Link
          to="/"
          className={`absolute top-1.5 left-1.5 w-[44px] h-[20px] flex items-center justify-center ${
            isHostsRoute ? 'text-gray-400' : 'text-transparent'
          }`}
        >
          <Home className="w-4 h-4" />
        </Link>
        <Link
          to="/hosts"
          className={`absolute top-1.5 right-1.5 w-[44px] h-[20px] flex items-center justify-center ${
            isHostsRoute ? 'text-transparent' : 'text-gray-400'
          }`}
        >
          <HostsIcon />
        </Link>
      </div>
      <Button
        onClick={onSignOut}
        className="bg-purple-600 hover:bg-purple-700 text-white w-[80px] h-[32px] text-xs"
      >
        <LogOut className="w-4 h-4 mr-1" />
        Sign Out
      </Button>
    </div>
  );
};

export default NavButtonGroup;
