import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

/*
### feedback

| name                            | type                     | format  | required |
|---------------------------------|--------------------------|---------|----------|
| feedback_id                     | uuid                     | string  | true     |
| user_id                         | uuid                     | string  | false    |
| meeting_id                      | uuid                     | string  | false    |
| feedback_type                   | text                     | string  | true     |
| content                         | text                     | string  | false    |
| media_url                       | text                     | string  | false    |
| transcript                      | text                     | string  | false    |
| highlight                       | text                     | string  | false    |
| rating                          | integer                  | number  | false    |
| is_testimonial                  | boolean                  | boolean | false    |
| linkedin_recommendation_url     | text                     | string  | false    |
| linkedin_recommendation_clicked | boolean                  | boolean | true     |
| created_at                      | timestamp with time zone | string  | false    |
| updated_at                      | timestamp with time zone | string  | false    |

Foreign Key Relationships:
- user_id references profiles.user_id
- meeting_id references meetings.meeting_id

*/

export const useFeedback = () => {
  const queryClient = useQueryClient();

  const getFeedback = async () => {
    const { data, error } = await supabase.from("feedback").select("*");
    if (error) throw error;
    return data;
  };

  const getFeedbackByUserId = async (userId) => {
    const { data, error } = await supabase
      .from("feedback")
      .select("*")
      .eq("user_id", userId);
    if (error) throw error;
    return data;
  };

  const addFeedback = async (newFeedback) => {
    const { data, error } = await supabase
      .from("feedback")
      .insert([newFeedback]);
    if (error) throw error;
    return data;
  };

  const updateFeedback = async ({ feedback_id, updatedFeedback }) => {
    const { data, error } = await supabase
      .from("feedback")
      .update(updatedFeedback)
      .eq("feedback_id", feedback_id);
    if (error) throw error;
    return data;
  };

  const deleteFeedback = async (feedback_id) => {
    const { data, error } = await supabase
      .from("feedback")
      .delete()
      .eq("feedback_id", feedback_id);
    if (error) throw error;
    return data;
  };

  const submitFeedback = async (feedbackData) => {
    const { data, error } = await supabase
      .from("feedback")
      .insert([feedbackData])
      .select()
      .single();
    if (error) throw error;
    return data;
  };

  const updateLinkedinRecommendationClicked = async ({ feedback_id }) => {
    const { data, error } = await supabase
      .from("feedback")
      .update({ linkedin_recommendation_clicked: true })
      .eq("feedback_id", feedback_id);
    if (error) throw error;
    return data;
  };

  return {
    useGetFeedback: () =>
      useQuery({ queryKey: ["feedback"], queryFn: getFeedback }),
    useGetFeedbackByUserId: (userId) =>
      useQuery({
        queryKey: ["feedback", userId],
        queryFn: () => getFeedbackByUserId(userId),
        enabled: !!userId,
      }),
    useAddFeedback: () =>
      useMutation({
        mutationFn: addFeedback,
        onSuccess: () => queryClient.invalidateQueries(["feedback"]),
        onError: (error) => {
          console.error("Error adding feedback:", error);
        },
      }),
    useUpdateFeedback: () =>
      useMutation({
        mutationFn: updateFeedback,
        onSuccess: () => queryClient.invalidateQueries(["feedback"]),
        onError: (error) => {
          console.error("Error updating feedback:", error);
        },
      }),
    useDeleteFeedback: () =>
      useMutation({
        mutationFn: deleteFeedback,
        onSuccess: () => queryClient.invalidateQueries(["feedback"]),
        onError: (error) => {
          console.error("Error deleting feedback:", error);
        },
      }),
    useSubmitFeedback: () =>
      useMutation({
        mutationFn: submitFeedback,
        onSuccess: () => queryClient.invalidateQueries(["feedback"]),
        onError: (error) => {
          console.error("Error submitting feedback:", error);
        },
      }),
    useUpdateLinkedinRecommendationClicked: () =>
      useMutation({
        mutationFn: updateLinkedinRecommendationClicked,
        onSuccess: () => queryClient.invalidateQueries(["feedback"]),
        onError: (error) => {
          console.error(
            "Error updating linkedin recommendation clicked:",
            error
          );
        },
      }),
  };
};
