import React from "react";
import UserName from "../atoms/UserName";
import UserLocation from "../atoms/UserLocation";

const UserInfo = ({ name, location }) => (
  <div className="flex flex-col sm:flex-row sm:items-center">
    <UserName name={name} />
    {location && <UserLocation location={location} />}
  </div>
);

export default UserInfo;
