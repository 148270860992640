import React from "react";
import { differenceInDays } from "date-fns";
import { useMeetings, useLinkedin } from "../../integrations/supabase";
import { useMeetingsContext } from "../../contexts/MeetingsContext";
import LastCallsSection from "../molecules/LastCallsSection";
import GuestProfileCard from "../molecules/GuestProfileCard";
import MatchesSection from "../molecules/MatchesSection";
import SpotlightPostModal from "./SpotlightPostModal";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import GuestHeader from "../molecules/GuestHeader";
import StatSection from "../molecules/StatSection";
import ActionButtons from "../molecules/ActionButtons";
import { Home, Mic, Star } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { useProfiles } from "../../integrations/supabase/hooks/useProfiles";

const CompletedGuestContent = ({
  name,
  location,
  careerStage,
  imageUrl,
  matches,
  interests,
  keySkills,
  businessGoals,
  guestUserId,
}) => {
  const { useGetMeetings } = useMeetings();
  const { useGetLinkedinProfileByUserId } = useLinkedin();
  const { useGetUserProfile } = useProfiles();
  const { data: guestMeetings, isLoading: isGuestMeetingsLoading } =
    useGetMeetings(guestUserId);
  const { data: linkedinProfile, isLoading: isLinkedinProfileLoading } =
    useGetLinkedinProfileByUserId(guestUserId);
  const { data: guestProfile } = useGetUserProfile(guestUserId);
  const [isSpotlightModalOpen, setIsSpotlightModalOpen] = React.useState(false);
  const { completedMeetings } = useMeetingsContext();
  const { session } = useSupabaseAuth();

  const isStatsLoading = isGuestMeetingsLoading || isLinkedinProfileLoading;

  const daysSinceRegistration =
    !isStatsLoading && guestMeetings && guestMeetings.length > 0
      ? differenceInDays(new Date(), new Date(guestMeetings[0].created_at))
      : null;

  const lastCallIn =
    !isStatsLoading && guestMeetings && guestMeetings.length > 0
      ? differenceInDays(
          new Date(),
          new Date(guestMeetings[guestMeetings.length - 1].event_start_time)
        )
      : null;

  const linkedInUpdated =
    !isStatsLoading && linkedinProfile?.updated_at
      ? `${differenceInDays(
          new Date(),
          new Date(linkedinProfile.updated_at)
        )} days ago`
      : null;

  const handleSpotlightGenerated = () => {
    setIsSpotlightModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsSpotlightModalOpen(false);
  };

  const completedGuestMeetings = !isGuestMeetingsLoading
    ? guestMeetings.filter((meeting) => meeting.meeting_status === "done")
    : [];

  const lastMeetingId =
    completedGuestMeetings.length > 0
      ? completedGuestMeetings[completedGuestMeetings.length - 1].meeting_id
      : "";

  const IconWithTooltip = ({ icon: Icon, active, tooltip }) => (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <div className={`cursor-pointer transform transition-all duration-200 hover:scale-110 ${active ? "opacity-100" : "opacity-50"}`}>
            <Icon className={`${active ? "text-black" : "text-gray-300"} w-5 h-5`} />
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p>{tooltip}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-4 sm:p-6 h-full flex flex-col">
      <div className="grid grid-cols-12 gap-4 mb-6">
        <div className="col-span-6">
          <GuestHeader
            name={name}
            location={location}
            careerStage={careerStage}
            imageUrl={imageUrl}
            needLatest={guestProfile?.need_latest}
          />
        </div>
        
        <div className="col-span-3 flex flex-col justify-between">
          <div className="flex justify-center items-center space-x-6 mb-4 px-2">
            <IconWithTooltip 
              icon={Home} 
              active={guestProfile?.interest_hosting} 
              tooltip={guestProfile?.interest_hosting ? "Interested in hosting" : "Not interested in hosting"}
            />
            <IconWithTooltip 
              icon={Mic} 
              active={guestProfile?.interest_podcast} 
              tooltip={guestProfile?.interest_podcast ? "Interested in podcasts" : "Not interested in podcasts"}
            />
            <IconWithTooltip 
              icon={Star} 
              active={guestProfile?.interest_spotlight} 
              tooltip={guestProfile?.interest_spotlight ? "Interested in spotlight" : "Not interested in spotlight"}
            />
          </div>
          <ActionButtons
            guestUserId={guestUserId}
            meetingId={lastMeetingId}
            hostId={session?.user?.id}
            onSpotlightGenerated={handleSpotlightGenerated}
          />
        </div>

        <div className="col-span-3">
          <StatSection
            isStatsLoading={isStatsLoading}
            daysSinceRegistration={daysSinceRegistration}
            lastCallIn={lastCallIn}
            linkedInUpdated={linkedInUpdated}
          />
        </div>
      </div>

      <div className="grid grid-cols-3 gap-4 flex-grow">
        <LastCallsSection guestMeetings={completedGuestMeetings} />
        <GuestProfileCard
          keySkills={keySkills}
          businessGoals={businessGoals}
          interests={interests}
        />
        <MatchesSection matches={matches} />
      </div>

      <SpotlightPostModal
        isOpen={isSpotlightModalOpen}
        onClose={handleCloseModal}
        guestName={name}
        hostEmail={completedMeetings[completedMeetings.length - 1]?.host_email}
      />
    </div>
  );
};

export default CompletedGuestContent;