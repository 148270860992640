import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

const ProfileAvatar = ({ imageUrl, name }) => (
  <Avatar className="w-16 h-16">
    <AvatarImage src={imageUrl} alt={name} />
    <AvatarFallback>{name ? name.charAt(0) : 'U'}</AvatarFallback>
  </Avatar>
);

export default ProfileAvatar;