import React, { useState } from "react";
import { Card, CardContent } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import { BarChart2 } from "lucide-react";
import MatchDataModal from "../organisms/MatchDataModal";

const MatchesSection = ({ matches }) => {
  const [selectedMatch, setSelectedMatch] = useState(null);

  const openModal = (match) => {
    setSelectedMatch(match);
  };

  const closeModal = () => {
    setSelectedMatch(null);
  };

  return (
    <Card className="h-full">
      <CardContent className="p-4">
        <h3 className="text-lg font-semibold mb-4">Matches:</h3>
        {matches && matches.length > 0 ? (
          matches.map((match, index) => (
            <div key={index} className="mb-4 flex items-center justify-between">
              <div className="flex items-center flex-grow">
                <Avatar className="h-10 w-10 mr-3">
                  <AvatarImage
                    src={match.matched_user.image_url}
                    alt={match.matched_user.name}
                  />
                  <AvatarFallback>
                    {match.matched_user.name.charAt(0)}
                  </AvatarFallback>
                </Avatar>
                <div className="flex-grow">
                  <div className="flex items-center justify-between">
                    <p className="font-semibold">{match.matched_user.name}</p>
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => openModal(match)}
                      className="p-0 h-6 w-6 ml-2"
                    >
                      <BarChart2 className="w-5 h-5" />
                    </Button>
                  </div>
                  <p className="text-sm text-gray-600">
                    {match.matched_user.location}
                  </p>
                  <p className="text-sm text-gray-600">
                    {match.matched_user.career_stage}
                  </p>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-sm">No matches available</p>
        )}
      </CardContent>
      {selectedMatch && (
        <MatchDataModal
          isOpen={!!selectedMatch}
          onClose={closeModal}
          matchData={selectedMatch}
        />
      )}
    </Card>
  );
};

export default MatchesSection;
