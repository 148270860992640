import React from "react";
import ReactPlayer from "react-player";

const NoMeetingsMessage = () => {
  return (
    <div className="flex flex-col items-center justify-center h-full w-full p-4">
      <div className="w-full max-w-3xl aspect-video">
        <ReactPlayer
          url="https://youtu.be/cpMdMWNnTAw"
          width="100%"
          height="100%"
          controls={true}
        />
      </div>
      <p className="mt-4 text-lg font-semibold text-gray-600">
        No meetings yet. Watch this video to learn more!
      </p>
    </div>
  );
};

export default NoMeetingsMessage;