import React from 'react';
import { Button } from "@/components/ui/button";
import RatingSection from './RatingSection';

const FeedbackForm = ({ rating, setRating, feedback, setFeedback, handleSubmit, handleClose, isSubmitting }) => (
  <>
    <div className="mb-6">
      <h2 className="text-xl sm:text-2xl font-bold text-[#172B4D] mb-2">How do you like our Service?</h2>
      <p className="text-sm sm:text-base text-[#172B4D]">Give us your Honest Feedback or send us a Testimonal</p>
    </div>
    <RatingSection rating={rating} setRating={setRating} />
    <div className="space-y-4 mb-6">
      <p className="text-sm sm:text-base text-[#172B4D] font-semibold">What are the main reasons for your rating?</p>
      <p className="text-sm sm:text-base text-[#172B4D] font-semibold">What feature are you missing most?</p>
      <textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        className="w-full h-24 sm:h-32 p-3 border border-gray-300 rounded-md resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
      />
    </div>
    <div className="flex justify-end space-x-4">
      <Button onClick={handleClose} variant="ghost" className="text-[#42526E] text-sm sm:text-base">
        Cancel
      </Button>
      <Button
        onClick={handleSubmit}
        className="bg-green-500 text-white hover:bg-green-600 text-sm sm:text-base"
        disabled={!rating || !feedback || isSubmitting}
      >
        Submit
      </Button>
    </div>
  </>
);

export default FeedbackForm;