import React, { useRef, useState, useEffect } from "react";
import { toast } from "sonner";
import { useProfiles } from "../../integrations/supabase/hooks/useProfiles";

const AudioRecorder = ({
  isRecording,
  onToggleRecording,
  userId,
  onMatchesReceived,
}) => {
  const [audioBlob, setAudioBlob] = useState(null);
  const mediaRecorder = useRef(null);
  const isProcessing = useRef(false);
  const { getUserProfileByEmail } = useProfiles();

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorder.current = new MediaRecorder(stream);

      const chunks = [];
      mediaRecorder.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.push(event.data);
        }
      };

      mediaRecorder.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/wav" });
        setAudioBlob(blob);
      };

      mediaRecorder.current.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
      toast.error(
        "Unable to access microphone. Please check your device settings and permissions."
      );
      onToggleRecording();
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current && mediaRecorder.current.state === "recording") {
      mediaRecorder.current.stop();
    }
  };

  const enrichMatchWithProfile = async (match) => {
    try {
      // ! This could be saved by just pulling a join at query time
      const profileData = await getUserProfileByEmail(match.email);
      return {
        matched_user: profileData,
        matching_score: match.matching_score,
        matched_skills: match.matched_skills,
        explanation: match.explanation,
        communication_compatibility: match.communication_compatibility,
        geographical_synergy: match.geographical_synergy,
        potential_collaboration: match.potential_collaboration,
        shared_interests: match.shared_interests,
      };
    } catch (error) {
      console.warn(`Could not fetch profile for ${match.email}:`, error);
      // Fallback to basic structure if profile fetch fails
      return {
        matched_user: {
          name: match.name,
          email: match.email,
          image_url: null,
        },
        matching_score: match.matching_score,
        matched_skills: match.matched_skills,
        explanation: match.explanation,
        communication_compatibility: match.communication_compatibility,
        geographical_synergy: match.geographical_synergy,
        potential_collaboration: match.potential_collaboration,
        shared_interests: match.shared_interests,
      };
    }
  };

  const sendAudioToWebhook = async () => {
    if (!audioBlob || isProcessing.current) return;
    isProcessing.current = true;

    try {
      const formData = new FormData();
      formData.append("audio", audioBlob, "recording.wav");
      formData.append("user_id", userId);

      const response = await fetch(
        "https://hook.eu1.make.com/rrtv3ycxi81wa4cfabhefbtvxul1db75",
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.json();
        if (data.request && data.matches && Array.isArray(data.matches)) {
          console.log("Received matches:", data.matches);
          toast.success("Processing matches...");

          // Fetch complete profile data for each match
          const enrichedMatches = await Promise.all(
            data.matches.map((match) => enrichMatchWithProfile(match))
          );

          console.log("Enriched matches:", enrichedMatches);
          onMatchesReceived(data.request, enrichedMatches);
        } else {
          throw new Error("Invalid response format");
        }
      } else {
        throw new Error("Failed to process audio");
      }
    } catch (error) {
      console.error("Error processing matches:", error);
      toast.error("Failed to process audio. Please try again.");
    } finally {
      isProcessing.current = false;
    }
  };

  useEffect(() => {
    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
    }

    return () => {
      if (mediaRecorder.current) {
        mediaRecorder.current.stream
          .getTracks()
          .forEach((track) => track.stop());
      }
    };
  }, [isRecording]);

  useEffect(() => {
    if (audioBlob && !isRecording) {
      sendAudioToWebhook();
    }
  }, [audioBlob, isRecording]);

  return null;
};

export default AudioRecorder;
