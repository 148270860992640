import { useQuery } from "@tanstack/react-query";
import { supabase } from "../supabase";

/*
### hosts

| name      | type                     | format      | required |
|-----------|--------------------------|-------------|----------|
| user_id   | uuid                     | uuid        | true     |
| status    | text                     | string      | true     |
| created_at| timestamp with time zone | timestamptz | false    |
| updated_at| timestamp with time zone | timestamptz | false    |

Foreign Key Relationships:
- user_id references auth.users.id

Additional Information:
- status: Can be either "host" or "admin"
- Tracks host privileges and admin status for users
*/

export const useHostStatus = () => {
  const getHostStatus = async (userId) => {
    if (!userId) return { isHost: false, isAdmin: false };

    const { data, error } = await supabase
      .from("hosts")
      .select("user_id, status")
      .eq("user_id", userId)
      .single();

    if (error) throw error;

    return {
      isHost: !!data,
      isAdmin: data?.status === "admin",
    };
  };

  return {
    useGetHostStatus: (userId) =>
      useQuery({
        queryKey: ["hostStatus", userId],
        queryFn: () => getHostStatus(userId),
        enabled: !!userId,
      }),
  };
};
