import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { EyeIcon, EyeOffIcon } from "lucide-react";
import { toast } from "sonner";
import AuthToggle from "../molecules/AuthToggle";

const LoginForm = () => {
  const { signInWithOTP, signInWithPassword } = useSupabaseAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [authMethod, setAuthMethod] = useState("magic-link");
  const [showPassword, setShowPassword] = useState(false);

  const handleSignIn = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      await signInWithAuthMethod();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const signInWithAuthMethod = async () => {
    if (authMethod === "magic-link") {
      const result = await signInWithOTP(email);
      if (!result.success) {
        if (result.error === "Signups not allowed for otp") {
          window.open("https://waitlist.voiceloop.io/", "_blank");
        }
        throw new Error(result.error);
      }
      toast.success(result.message);
      return;
    }

    const result = await signInWithPassword({ email, password });
    if (!result.success) throw new Error(result.error);
    navigate("/");
  };

  return (
    <form onSubmit={handleSignIn}>
      <AuthToggle activeMethod={authMethod} onToggle={setAuthMethod} />
      <div className="grid w-full items-center gap-4">
        <div className="flex flex-col space-y-1.5">
          <Label htmlFor="email">Email</Label>
          <Input
            id="email"
            type="email"
            placeholder="Your email address"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        {authMethod === "password" && (
          <div className="flex flex-col space-y-1.5">
            <Label htmlFor="password">Password</Label>
            <div className="relative">
              <Input
                id="password"
                type={showPassword ? "text" : "password"}
                placeholder="Your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              <Button
                type="button"
                variant="ghost"
                size="sm"
                className="absolute right-0 top-0 h-full px-3 py-2 hover:bg-transparent"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <EyeOffIcon className="h-4 w-4" />
                ) : (
                  <EyeIcon className="h-4 w-4" />
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
      <Button
        className="w-full mt-4"
        type="submit"
        disabled={isLoading}
      >
        {isLoading
          ? "Processing..."
          : authMethod === "magic-link"
          ? "Send Magic Link"
          : "Sign In"}
      </Button>
    </form>
  );
};

export default LoginForm;