import React from "react";
import { Globe, Calendar } from "lucide-react";
import { ensureAbsoluteUrl } from "@/lib/utils";

const ProfileLinks = ({ websiteUrl, calendarUrl }) => {
  if (!websiteUrl && !calendarUrl) return null;

  return (
    <div className="space-y-3">
      <h3 className="font-semibold">Links</h3>
      {websiteUrl && (
        <div className="flex items-center space-x-2 text-sm">
          <Globe className="h-4 w-4 text-gray-500" />
          <a
            href={ensureAbsoluteUrl(websiteUrl)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Website
          </a>
        </div>
      )}
      {calendarUrl && (
        <div className="flex items-center space-x-2 text-sm">
          <Calendar className="h-4 w-4 text-gray-500" />
          <a
            href={ensureAbsoluteUrl(calendarUrl)}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 hover:underline"
          >
            Calendar
          </a>
        </div>
      )}
    </div>
  );
};

export default ProfileLinks;
