import React from "react";
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";

const UserAvatar = ({
  imageUrl,
  name,
  size = "h-12 w-12 sm:h-16 sm:w-16",
}) => {
  return (
    <div className="relative">
      <Avatar className={`${size} z-40`}>
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback>{name?.charAt(0)}</AvatarFallback>
      </Avatar>
    </div>
  );
};

export default UserAvatar;