import React from "react";
import { motion } from "framer-motion";
import { useMatch } from "../../contexts/MatchContext";
import MatchCardHeader from "../molecules/MatchCardHeader";
import ExpandedSection from "../molecules/ExpandedSection";
import MatchCardButtons from "../molecules/MatchCardButtons";

const MatchCard = (match) => {
  const { expandedMatchId, collapsingMatchId, expandingMatchId, handleExpand, isExpandable } =
    useMatch();

  const isExpanded = match.match_id === expandedMatchId;
  const isCollapsing = match.match_id === collapsingMatchId;
  const isExpanding = match.match_id === expandingMatchId;

  const isReceiver = match.isReceiver;

  const baseCardClasses = `
    rounded-lg shadow-lg border border-gray-100 ${isExpandable ? 'cursor-pointer' : ''} transition-all duration-300
    ${isExpanded ? "bg-gray-50" : "bg-white hover:shadow-xl"}
    ${
      match.connection_status === "pending" && isReceiver
        ? "border-2 border-dotted border-gray-300"
        : ""
    }
  `;

  const handleClick = () => {
    if (isExpandable) {
      handleExpand(match.match_id);
    }
  };

  return (
    <div
      className={baseCardClasses}
      onClick={handleClick}
    >
      <div className="p-6">
        <MatchCardHeader {...match} />
        <motion.div
          className="overflow-hidden"
          initial={false}
          animate={{
            height: isExpanded || isExpanding ? "auto" : 0,
            opacity: isExpanded || isExpanding ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          {(isExpanded || isExpanding || isCollapsing) && (
            <div
              className={`mt-4 pt-4 border-t transition-opacity duration-300 ${
                isCollapsing ? "opacity-0" : "opacity-100"
              }`}
            >
              <ExpandedSection {...match} />
              <MatchCardButtons
                matched_user={match.matched_user}
                onLocationSelect={match.onLocationSelect}
                onFollowConfirm={match.onFollowConfirm}
              />
            </div>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default MatchCard;