import React from 'react';
import logo from "/public/logo_voiceloop.png";

const LoadingSpinner = () => {
  return (
    <div className="flex items-center justify-center min-h-screen">
      <img src={logo} alt="Loading" className="h-12 w-12" />
    </div>
  );
};

export default LoadingSpinner;
