import React from 'react';
import HostAvatar from '../atoms/HostAvatar';

const HostHeader = ({ name, location, careerStage, imageUrl }) => (
  <div className="flex flex-row items-center space-x-2 sm:space-x-4 p-3 sm:p-6 pb-2">
    <HostAvatar name={name} imageUrl={imageUrl} />
    <div>
      <h2 className="text-lg sm:text-2xl font-semibold">{name}</h2>
      <p className="text-sm sm:text-base text-gray-500">{location}</p>
      <p className="text-sm sm:text-base text-gray-500">{careerStage}</p>
    </div>
  </div>
);

export default HostHeader;
