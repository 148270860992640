import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";

const MatchDataModal = ({ isOpen, onClose, matchData }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="w-[60vw] max-w-[800px]">
        <DialogHeader>
          <DialogTitle>Match Data for {matchData.matched_user?.name || 'Unknown'}</DialogTitle>
        </DialogHeader>
        <div className="grid grid-cols-2 gap-4">
          <div className="border p-2 rounded">
            <h3 className="font-semibold">Match Score</h3>
            <p>{matchData.matching_score || 'N/A'}</p>
          </div>
          <div className="border p-2 rounded">
            <h3 className="font-semibold">Complementary Skills</h3>
            <p>{matchData.complementary_skills?.join(', ') || 'N/A'}</p>
          </div>
          <div className="border p-2 rounded">
            <h3 className="font-semibold">Shared Interests</h3>
            <p>{matchData.shared_interests?.join(', ') || 'N/A'}</p>
          </div>
          <div className="border p-2 rounded">
            <h3 className="font-semibold">Potential Collaboration</h3>
            <p>{matchData.potential_collaboration || 'N/A'}</p>
          </div>
        </div>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MatchDataModal;
