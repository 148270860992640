import React from "react";
import MatchCard from "./MatchCard";
import { useMatch } from "../../contexts/MatchContext";

const MatchList = () => {
  const { matches } = useMatch();

  if (!matches || matches.length === 0) {
    return <p>No matches available.</p>;
  }

  return (
    <div className="space-y-4">
      {matches.map((match, index) => (
        <MatchCard key={match.match_id || index} index={index} {...match} />
      ))}
    </div>
  );
};

export default MatchList;
