import React from "react";
import ReactMarkdown from "react-markdown";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { useMeetings } from "../../integrations/supabase";
import { Skeleton } from "@/components/ui/skeleton";
import { ScrollArea } from "@/components/ui/scroll-area";

const MeetingSummaryModal = ({
  isOpen,
  onClose,
  hostName,
  summary,
  meetingId,
  hostUserId,
}) => {
  const { useGetHostFeedback } = useMeetings();
  const { data: hostFeedback, isLoading: isHostFeedbackLoading } =
    useGetHostFeedback(meetingId, hostUserId);

  const markdownComponents = {
    h1: ({ children }) => <h1 className="text-2xl font-bold mt-4 mb-2">{children}</h1>,
    h2: ({ children }) => <h2 className="text-xl font-semibold mt-3 mb-2">{children}</h2>,
    h3: ({ children }) => <h3 className="text-lg font-medium mt-2 mb-1">{children}</h3>,
    p: ({ children }) => <p className="mb-4">{children}</p>,
    ul: ({ children }) => <ul className="list-disc pl-6 mb-4">{children}</ul>,
    ol: ({ children }) => <ol className="list-decimal pl-6 mb-4">{children}</ol>,
    li: ({ children }) => <li className="mb-1">{children}</li>,
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[600px] h-[80vh] flex flex-col">
        <DialogHeader>
          <DialogTitle>After Meeting Note from {hostName}</DialogTitle>
        </DialogHeader>
        <ScrollArea className="flex-grow">
          <div className="py-4 pr-4">
            <div className="mb-4">
              <h3 className="text-lg font-semibold mb-2">Host Feedback:</h3>
              {isHostFeedbackLoading ? (
                <Skeleton className="h-16 w-full" />
              ) : hostFeedback ? (
                <p className="text-sm sm:text-base">{hostFeedback}</p>
              ) : (
                <p className="text-sm sm:text-base text-gray-500 italic">
                  No feedback available
                </p>
              )}
            </div>
            <h3 className="text-lg font-semibold mb-2">Meeting Summary:</h3>
            <div className="prose max-w-none text-sm sm:text-base">
              <ReactMarkdown components={markdownComponents}>
                {summary || "No summary available"}
              </ReactMarkdown>
            </div>
            <p className="text-xs text-gray-500 italic mt-4">
              This summary is generated based on the meeting data.
            </p>
          </div>
        </ScrollArea>
        <DialogFooter>
          <Button onClick={onClose}>Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default MeetingSummaryModal;
