import React, { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { useFeedback } from "../../integrations/supabase";
import { toast } from "sonner";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";

const PostMeetingMandatoryFeedback = ({
  isOpen,
  onClose,
  meetingId,
  onFeedbackSubmit,
}) => {
  const [feedback, setFeedback] = useState("");
  const { useSubmitFeedback } = useFeedback();
  const submitFeedbackMutation = useSubmitFeedback();
  const [showConfetti, setShowConfetti] = useState(false);
  const { width, height } = useWindowSize();

  const handleSubmit = async () => {
    if (!feedback.trim()) {
      toast.error("Please provide feedback before submitting.");
      return;
    }

    try {
      await submitFeedbackMutation.mutateAsync({
        meeting_id: meetingId,
        content: feedback,
        feedback_type: "post_meeting",
      });
      onClose(); // Close the modal first
      toast.success("Feedback submitted successfully!");
      setShowConfetti(true);
      setTimeout(() => {
        setShowConfetti(false);
        onFeedbackSubmit(); // Call the onFeedbackSubmit prop
      }, 3000); // Hide confetti after 3 seconds
    } catch (error) {
      console.error("Error submitting feedback:", error);
      toast.error("Failed to submit feedback. Please try again.");
    }
  };

  return (
    <>
      {showConfetti && <Confetti width={width} height={height} />}
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Your VoiceLoop Experience Matters!</DialogTitle>
            <DialogDescription>
              As a valued pre-beta tester, you agreed to help us improve
              VoiceLoop through your honest feedback. Your insights are crucial
              for our development.
            </DialogDescription>
          </DialogHeader>
          <div className="mt-2">
            <p className="text-sm text-gray-500 mb-4">
              Please share your thoughts on the onboarding process and/or your
              discovery call. Consider aspects that worked well, any challenges
              you faced, and suggestions for improvement. How likely are you to
              recommend VoiceLoop to a member, and why?
            </p>
            <Textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              placeholder="Your feedback (please be as detailed as possible)"
              className="h-40"
            />
          </div>
          <p className="text-xs text-center text-gray-500">
            Thank you for your time and insights. Your input directly shapes the
            future of VoiceLoop!
          </p>
          <DialogFooter className="mt-4">
            <Button
              onClick={handleSubmit}
              disabled={submitFeedbackMutation.isLoading}
            >
              {submitFeedbackMutation.isLoading
                ? "Submitting..."
                : "Submit Feedback"}
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PostMeetingMandatoryFeedback;
