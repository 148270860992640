import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "./index.css";
import { SupabaseAuthProvider } from "./integrations/supabase/auth";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://8010f75e8b2061dacacbbc4290b53f41@o4507900568535040.ingest.de.sentry.io/4507986194858064",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. Adjust as needed
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const SentryErrorBoundary = ({ children }) => (
  <Sentry.ErrorBoundary fallback={<ErrorFallback />} showDialog>
    {children}
  </Sentry.ErrorBoundary>
);

const ErrorFallback = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <div className="text-center">
        <h1 className="text-9xl font-bold text-gray-700 mb-4">500</h1>
        <p className="text-2xl font-semibold text-gray-600 mb-2">
          You weren't meant to see this...
        </p>
        <p className="text-gray-500 mb-8">
          An error occurred while processing your request.
        </p>
        <p className="text-sm text-gray-400 mb-8">
          The error has been recorded and the team is working on a fix!
        </p>
        <a
          href="/"
          className="px-6 py-3 bg-gray-700 text-white rounded-md hover:bg-gray-800 transition-colors"
        >
          Take Me Back
        </a>
      </div>
    </div>
  );
};

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <BrowserRouter>
      <SentryErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <SupabaseAuthProvider>
            <App />
          </SupabaseAuthProvider>
        </QueryClientProvider>
      </SentryErrorBoundary>
    </BrowserRouter>
  </React.StrictMode>
);
