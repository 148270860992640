import React from 'react';

const Logo = ({ onClick }) => (
  <img
    src="https://ik.imagekit.io/libralab/VoiceLoop/logo_designs_V1_banner.png"
    alt="VoiceLoop Logo"
    className="h-8 w-auto cursor-pointer"
    onClick={onClick}
  />
);

export default Logo;