import React from "react";
import ProfileAvatar from "../atoms/ProfileAvatar";
import ProfileName from "../atoms/ProfileName";
import ProfileCareerStage from "../atoms/ProfileCareerStage";
import { Input } from "@/components/ui/input";

const ProfileHeader = ({
  profile,
  isEditing,
  onInputChange,
  newImagePreview,
  children,
}) => {
  return (
    <div className="flex items-start space-x-4 mb-4">
      <div className="relative">
        <ProfileAvatar
          imageUrl={newImagePreview || profile.image_url}
          name={profile.name}
        />
        {children}
      </div>
      <div className="flex-grow">
        <ProfileName name={profile.name} />
        {isEditing ? (
          <Input
            value={profile.career_stage}
            onChange={(e) => onInputChange("career_stage", e.target.value)}
            className="mt-1 w-full max-w-[300px]"
            placeholder="Career Stage"
          />
        ) : (
          <ProfileCareerStage careerStage={profile.career_stage} />
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
