import React from "react";
import { Navigate } from "react-router-dom";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import LoadingSpinner from "./atoms/LoadingSpinner";

const ProtectedRoute = ({ children }) => {
  const { session, loading } = useSupabaseAuth();

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default ProtectedRoute;
