import React, { useState } from "react";
import StatItem from "../atoms/StatItem";
import {
  Phone,
  Clock,
  UserMinus,
  MessageSquare,
  Users,
  Copy,
  Check,
} from "lucide-react";
import { useMeetingsContext } from "../../contexts/MeetingsContext";
import { useFeedback } from "../../integrations/supabase";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { Skeleton } from "@/components/ui/skeleton";
import { Button } from "@/components/ui/button";
import { motion, AnimatePresence } from "framer-motion";
import { useHostStatus } from "../../integrations/supabase/hooks/useHostStatus";
import MeetingsList from "../molecules/MeetingsList";
import { useReferrals } from "../../hooks/useReferrals";
import { toast } from "sonner";

const HostSidebar = () => {
  const {
    scheduledMeetings,
    completedMeetings,
    noShowMeetings,
    isLoading,
    currentMeeting,
    switchCurrentMeeting,
  } = useMeetingsContext();
  const { session } = useSupabaseAuth();
  const { useGetFeedbackByUserId } = useFeedback();
  const { data: feedbacks, isLoading: isFeedbackLoading } =
    useGetFeedbackByUserId(session?.user?.id);
  const [activeTab, setActiveTab] = useState("upcoming");
  const [displayedMeetings, setDisplayedMeetings] = useState(5);
  const { useGetHostStatus } = useHostStatus();
  const { data: hostStatus } = useGetHostStatus(session?.user?.id);
  const { isAdmin } = hostStatus || {};
  const {
    referredUsersCount,
    referralCode,
    isLoading: isReferralsLoading,
  } = useReferrals(session?.user?.id);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyReferralLink = async () => {
    const referralLink = `connect.voiceloop.io/?via=${referralCode}`;
    await navigator.clipboard.writeText(referralLink);
    setIsCopied(true);
    toast.success("Referral link copied to clipboard!");

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const loadMoreMeetings = () => {
    setDisplayedMeetings((prev) => prev + 5);
  };

  if (isLoading || isFeedbackLoading || isReferralsLoading) {
    return <HostSidebarSkeleton />;
  }

  const calculateAverageDuration = () => {
    const totalDuration = completedMeetings.reduce(
      (sum, meeting) => sum + (meeting.duration || 0),
      0
    );
    return completedMeetings.length > 0
      ? Math.round(totalDuration / completedMeetings.length)
      : 0;
  };

  const noShowsCount = noShowMeetings.length;
  const collectedFeedbacksCount = feedbacks ? feedbacks.length : 0;

  const getMeetingsForActiveTab = () => {
    switch (activeTab) {
      case "upcoming":
        return scheduledMeetings;
      case "completed":
        return completedMeetings;
      case "noshow":
        return noShowMeetings;
      default:
        return [];
    }
  };

  return (
    <div className="bg-white rounded-lg h-full p-6">
      <h2 className="text-3xl font-bold text-blue-500 mb-6">
        {isAdmin ? "All Guests" : "Your Guests"}
      </h2>

      <div className="flex flex-wrap gap-2 mb-4">
        <Button
          onClick={() => setActiveTab("upcoming")}
          variant={activeTab === "upcoming" ? "default" : "outline"}
          className="flex-grow"
        >
          Upcoming
        </Button>
        <Button
          onClick={() => setActiveTab("completed")}
          variant={activeTab === "completed" ? "default" : "outline"}
          className="flex-grow"
        >
          Completed
        </Button>
        <Button
          onClick={() => setActiveTab("noshow")}
          variant={activeTab === "noshow" ? "default" : "outline"}
          className="flex-grow"
        >
          No Shows
        </Button>
      </div>
      <AnimatePresence mode="wait">
        <motion.div
          key={activeTab}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.15 }}
          className="space-y-2 mb-6 bg-gray-50 rounded-lg p-4 shadow-inner shadow-gray-200 h-64 overflow-y-auto"
          id="meetingsContainer"
        >
          <MeetingsList
            meetings={getMeetingsForActiveTab()}
            currentMeeting={currentMeeting}
            switchCurrentMeeting={switchCurrentMeeting}
            emptyMessage={`No ${activeTab} meetings found.`}
            isAdmin={isAdmin}
            displayedMeetings={displayedMeetings}
            loadMoreMeetings={loadMoreMeetings}
          />
        </motion.div>
      </AnimatePresence>
      <div className="space-y-4 bg-gray-50 rounded-lg p-4 shadow-inner shadow-gray-200">
        <StatItem
          icon={Phone}
          label="Completed Calls"
          value={completedMeetings.length}
        />
        <StatItem
          icon={Clock}
          label="Avg. Call Duration"
          value={`${calculateAverageDuration()} min`}
        />
        <StatItem icon={UserMinus} label="No-Shows" value={noShowsCount} />
        <StatItem
          icon={MessageSquare}
          label="Collected Feedbacks"
          value={collectedFeedbacksCount}
        />
        <StatItem
          icon={Users}
          label="Referred Users"
          value={referredUsersCount || 0}
        />
      </div>

      {referralCode && (
        <div className="mt-4 p-4 bg-gray-50 rounded-lg shadow-inner shadow-gray-200">
          <div className="flex items-center justify-between gap-2 text-sm text-gray-600">
            <span className="truncate">
              connect.voiceloop.io/?via={referralCode}
            </span>
            <AnimatePresence mode="wait" initial={false}>
              <motion.div
                key={isCopied ? "check" : "copy"}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.15 }}
              >
                <Button
                  variant="ghost"
                  size="icon"
                  onClick={handleCopyReferralLink}
                  className="shrink-0"
                >
                  {isCopied ? (
                    <Check className="h-4 w-4 text-green-500" />
                  ) : (
                    <Copy className="h-4 w-4" />
                  )}
                </Button>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      )}
    </div>
  );
};

const HostSidebarSkeleton = () => (
  <div className="bg-white rounded-lg h-full p-6 space-y-6">
    <Skeleton className="h-8 w-3/4" />
    <div className="space-y-2">
      <Skeleton className="h-6 w-1/2" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
    <div className="space-y-2">
      <Skeleton className="h-64 w-full" />
    </div>
    <div className="space-y-4">
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
      <Skeleton className="h-6 w-full" />
    </div>
  </div>
);

export default HostSidebar;
