import React, { useState, useCallback } from "react";
import { X, Mic } from "lucide-react";
import { motion } from "framer-motion";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import AudioRecorder from "../molecules/AudioRecorder";
import TalentMatchList from "../molecules/TalentMatchList";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { Oval } from "react-loader-spinner";

const TalentTrackerPopup = ({ isOpen, onClose }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [matches, setMatches] = useState([]);
  const [request, setRequest] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const { session } = useSupabaseAuth();

  const toggleRecording = () => {
    if (isRecording) {
      setIsWaiting(true);
      setIsProcessing(true);
    } else {
      setHasSearched(false);
      setRequest("");
      setIsWaiting(false);
      setIsProcessing(false);
    }
    setIsRecording(!isRecording);
  };

  const handleMatchesReceived = useCallback(
    (receivedRequest, receivedMatches) => {
      setRequest(receivedRequest);
      setMatches(receivedMatches);
      setHasSearched(true);
      setIsWaiting(false);
      setIsRecording(false);
      setIsProcessing(false);
    },
    []
  );

  const pulseVariants = {
    start: { scale: 1 },
    end: { scale: 1.1, transition: { duration: 0.5, yoyo: Infinity } },
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent
        className={`w-full overflow-hidden transition-all duration-300 ${
          hasSearched ? "sm:max-w-[1000px]" : "sm:max-w-[600px]"
        }`}
      >
        <div className="h-full w-full flex flex-col items-center justify-center">
          <div
            className={`transition-all duration-300 ease-in-out ${
              hasSearched ? "max-h-[80vh]" : "max-h-[50vh]"
            } overflow-y-auto w-full`}
          >
            <h2 className="text-3xl font-bold mb-4 text-center">
              Talent Tracker
            </h2>
            <p className="text-lg mb-8 text-center max-w-2xl mx-auto">
              Describe in your words what skills, language, country, expertise,
              etc. you are looking for:
            </p>
            <div className="flex flex-col items-center justify-center mb-8 relative">
              <motion.div
                variants={isProcessing ? pulseVariants : {}}
                initial="start"
                animate="end"
                className="relative z-10"
              >
                <Button
                  onClick={toggleRecording}
                  className={`rounded-full p-6 w-20 h-20 flex items-center justify-center ${
                    isRecording
                      ? "bg-red-500 hover:bg-red-600"
                      : "bg-blue-500 hover:bg-blue-600"
                  }`}
                >
                  {isWaiting ? (
                    <Oval
                      height={32}
                      width={32}
                      color="white"
                      visible={true}
                      ariaLabel="oval-loading"
                      secondaryColor="rgba(255, 255, 255, 0.2)"
                      strokeWidth={4}
                      strokeWidthSecondary={4}
                    />
                  ) : (
                    <Mic className="h-8 w-8 text-white" />
                  )}
                </Button>
              </motion.div>
              {isProcessing && (
                <p className="mt-4 text-sm italic text-gray-600">
                  processing..
                </p>
              )}
            </div>
            <AudioRecorder
              isRecording={isRecording}
              onToggleRecording={toggleRecording}
              userId={session?.user?.id}
              onMatchesReceived={handleMatchesReceived}
            />
            {hasSearched && (
              <div className="w-full mt-8">
                {request && (
                  <p className="text-lg italic text-center mb-4">"{request}"</p>
                )}
                <h3 className="text-2xl font-bold mb-4 text-center">Matches</h3>
                <TalentMatchList matches={matches} />
              </div>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default TalentTrackerPopup;