import React from 'react';
import { Card, CardHeader, CardTitle, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import MeetingDetail from '../atoms/MeetingDetail';
import { format } from "date-fns";

const MeetingCard = ({ meeting }) => {
  const formatDate = (dateString) => {
    return dateString ? format(new Date(dateString), "MMMM d, yyyy 'at' h:mm a") : "Not given";
  };

  const displayValue = (value) => {
    return value ? value : "Not given";
  };

  return (
    <Card className="mb-6">
      <CardHeader>
        <CardTitle className="text-xl font-semibold text-purple-600">{displayValue(meeting.meeting_title)}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="space-y-4">
          <MeetingDetail label="Date" value={formatDate(meeting.event_start_time)} />
          <MeetingDetail label="Location" value={displayValue(meeting.meeting_timezone)} />
          <div>
            <p className="font-semibold">Meeting Link:</p>
            {meeting.meeting_url ? (
              <a href={meeting.meeting_url} target="_blank" rel="noopener noreferrer" className="bg-gray-100 rounded-md px-3 py-2 text-blue-500 hover:underline block">
                Join Meeting
              </a>
            ) : (
              <p className="bg-gray-100 rounded-md px-3 py-2">Not given</p>
            )}
          </div>
          <MeetingDetail label="Host" value={displayValue(meeting.host_email)} />
          <MeetingDetail label="Guest" value={displayValue(meeting.guest_email)} />
          <div className="mt-6 space-x-2">
            {meeting.rescheduleorcancel_url ? (
              <Button variant="outline" className="text-blue-500 border-blue-500 hover:bg-blue-50" onClick={() => window.open(meeting.rescheduleorcancel_url, '_blank')}>
                Cancel or Reschedule
              </Button>
            ) : (
              <Button variant="outline" className="text-gray-400 border-gray-400 cursor-not-allowed" disabled>
                Cancel or Reschedule (Not available)
              </Button>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default MeetingCard;