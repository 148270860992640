import React, { useState } from "react";
import UserAvatar from "../atoms/UserAvatar";
import UserInfo from "../atoms/UserInfo";
import CareerStageBadge from "../atoms/CareerStageBadge";
import MatchScore from "../atoms/MatchScore";
import ConnectionBadge from "./ConnectionBadge";
import { MapPinCheck } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { UserPlus } from "lucide-react";

const MatchCardHeader = (match) => {
  const {
    matched_user,
    matching_score,
    match_id,
    meet_location: initialMeetLocation,
    connection_status,
    isReceiver,
  } = match;

  const { name, location, career_stage, image_url } = matched_user;
  const [meetLocation, setMeetLocation] = useState(initialMeetLocation);

  return (
    <div className="flex flex-col w-full sm:flex-row sm:items-start sm:justify-between relative">
      <div className="flex flex-col w-full sm:w-[calc(100%-200px)] sm:flex-row items-center mb-4 sm:mb-0">
        <UserAvatar
          imageUrl={image_url}
          name={name}
          hasMet={!!meetLocation}
          meetLocation={meetLocation}
        />
        <div className="ml-4 w-full">
          <UserInfo name={name} location={location} />
          <div className="flex flex-wrap mt-1 flex-col sm:flex-row sm:gap-2 space-y-2 sm:space-y-0 items-start sm:items-center w-full">
            {meetLocation && (
              <Tooltip>
                <TooltipTrigger>
                  <div className="bg-gray-50 rounded-full p-1 shadow-sm border border-gray-100">
                    <MapPinCheck className="h-4 w-4 text-green-500 flex-shrink-0" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>
                    You met {name} via {meetLocation}
                  </p>
                </TooltipContent>
              </Tooltip>
            )}
            {connection_status === "accepted" && (
              <Tooltip>
                <TooltipTrigger>
                  <div className="bg-gray-50 rounded-full p-1 shadow-sm border border-gray-100">
                    <UserPlus className="h-4 w-4 text-purple-700 flex-shrink-0" />
                  </div>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Connected with {name}</p>
                </TooltipContent>
              </Tooltip>
            )}
            {career_stage && <CareerStageBadge careerStage={career_stage} />}
            <ConnectionBadge
              userName={name}
              meetLocation={meetLocation}
              matchId={match_id}
              connection_status={connection_status}
              isReceiver={isReceiver}
              onLocationUpdate={setMeetLocation}
            />
          </div>
        </div>
      </div>
      <div className="sm:w-[100px]">
        <MatchScore score={matching_score} />
      </div>
    </div>
  );
};

export default MatchCardHeader;
