import React from "react";
import {
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { HandshakeIcon } from "lucide-react";

const FollowConfirmationModal = ({ onConfirm, onClose, userName }) => {
  const handleConfirm = (e) => {
    e.stopPropagation();
    onConfirm();
  };

  const handleClose = (e) => {
    e.stopPropagation();
    onClose();
  };

  return (
    <AlertDialogContent className="max-w-md">
      <div className="flex flex-col items-center text-center p-4">
        <div className="w-20 h-20 mb-6 text-purple-600 bg-gray-50 rounded-full p-4 shadow-inner">
          <HandshakeIcon className="w-full h-full" />
        </div>
        <AlertDialogHeader className="space-y-4 text-center">
          <AlertDialogTitle className="text-2xl font-semibold text-center">
            Confirm Your Connection
          </AlertDialogTitle>
          <AlertDialogDescription className="text-gray-600 text-lg leading-relaxed text-center">
            Had a great conversation with {userName}? Confirm your match to stay
            connected and inspire others in our community!
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex w-full gap-3 mt-8">
          <Button
            variant="outline"
            onClick={handleClose}
            className="flex-1 text-base py-6"
          >
            Maybe Later
          </Button>
          <Button
            onClick={handleConfirm}
            className="flex-1 bg-purple-600 hover:bg-purple-700 text-base py-6"
          >
            Confirm Match
          </Button>
        </AlertDialogFooter>
      </div>
    </AlertDialogContent>
  );
};

export default FollowConfirmationModal;