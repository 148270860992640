import React from "react";
import { Replace } from "lucide-react";

const ImageUpload = ({ isEditing, onImageUpload }) => {
  if (!isEditing) return null;

  return (
    <div className="absolute inset-0 bg-gray-500 bg-opacity-50 flex items-center justify-center rounded-full">
      <label htmlFor="avatar-upload" className="cursor-pointer">
        <Replace className="h-8 w-8 text-white" />
      </label>
      <input
        id="avatar-upload"
        type="file"
        accept="image/*"
        className="hidden"
        onChange={(e) => onImageUpload(e.target.files[0])}
      />
    </div>
  );
};

export default ImageUpload;