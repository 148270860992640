import React from "react";
import { Button } from "@/components/ui/button";
import HostAvatar from "../atoms/HostAvatar";
import { useFeedback } from "../../integrations/supabase";
import { toast } from "sonner";

const ThankYouCard = ({
  rating,
  hostName,
  hostImageUrl,
  testimonialUrl,
  feedbackId,
  className = "",
}) => {
  const { useUpdateLinkedinRecommendationClicked } = useFeedback();
  const updateLinkedinRecommendationClicked = useUpdateLinkedinRecommendationClicked();

  const handleLinkedinRecommendationClick = async () => {
    try {
      await updateLinkedinRecommendationClicked.mutateAsync({
        feedback_id: feedbackId,
      });
      window.open(testimonialUrl, "_blank");
    } catch (error) {
      toast.error("Failed to update recommendation status");
    }
  };

  return (
    <div className={`flex flex-col sm:flex-row items-center justify-between h-full w-full ${className}`}>
      <div className="w-full sm:w-2/3 flex flex-col justify-center sm:pl-4">
        {rating >= 4 ? (
          <>
            <h2 className="text-xl sm:text-2xl font-bold mb-4 text-purple-600">
              Great to hear that you had a good experience!
            </h2>
            <p className="mb-6 text-sm sm:text-base">
              Feel invited to also directly send some support to{" "}
              <span className="font-bold">{hostName || 'your host'}</span> if you want to
              provide some value back to them:
            </p>
            {testimonialUrl ? (
              <Button
                onClick={handleLinkedinRecommendationClick}
                className="bg-blue-600 text-white hover:bg-blue-700 w-fit text-sm sm:text-base"
              >
                LinkedIn Recommendation
              </Button>
            ) : (
              <Button
                disabled
                className="bg-gray-400 text-white w-fit cursor-not-allowed text-sm sm:text-base"
              >
                LinkedIn Recommendation Unavailable
              </Button>
            )}
          </>
        ) : (
          <>
            <h2 className="text-xl sm:text-2xl font-bold mb-4">
              Thank you for your feedback!
            </h2>
            <p className="text-sm sm:text-base">
              We appreciate your input and will use it to improve our service.
            </p>
          </>
        )}
      </div>
      <div className="w-full sm:w-1/3 flex justify-center items-center mb-6 sm:mb-0"> {/* Changed justify-start to justify-center and removed sm:pr-4 */}
        <HostAvatar name={hostName} imageUrl={hostImageUrl} />
      </div>
    </div>
  );
};

export default ThankYouCard;
