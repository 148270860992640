import React, { useState, useCallback, useMemo } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import ListItem from "../atoms/ListItem";
import AddItemRow from "./AddItemRow";

const EditableList = React.memo(
  ({
    items,
    isEditing,
    onUpdate,
    selectedItems,
    onSelectItem,
    isAdding,
    onAddingComplete,
    title,
  }) => {
    const [editingIndex, setEditingIndex] = useState(null);

    const handleEditItem = useCallback((index) => {
      setEditingIndex(index);
    }, []);

    const handleApproveEdit = useCallback(
      (index, value) => {
        const updatedItems = [...items];
        updatedItems[index] = value;
        onUpdate(updatedItems);
        setEditingIndex(null);
      },
      [items, onUpdate]
    );

    const handleCancelEdit = useCallback(() => {
      setEditingIndex(null);
    }, []);

    const handleApproveAdd = useCallback(
      (newItem) => {
        if (newItem.trim()) {
          const updatedItems = [newItem.trim(), ...items];
          onUpdate(updatedItems);
        }
        onAddingComplete();
      },
      [items, onUpdate, onAddingComplete]
    );

    const memoizedItems = useMemo(
      () =>
        items.map((item, index) => (
          <ListItem
            key={index}
            item={item}
            index={index}
            isEditing={editingIndex === index}
            isSelected={selectedItems.includes(index)}
            onSelect={() => onSelectItem(index)}
            onEdit={() => handleEditItem(index)}
            onApprove={(value) => handleApproveEdit(index, value)}
            onCancel={handleCancelEdit}
          />
        )),
      [
        items,
        editingIndex,
        selectedItems,
        onSelectItem,
        handleEditItem,
        handleApproveEdit,
        handleCancelEdit,
      ]
    );

    const renderDefaultRow = () => (
      <TableRow>
        <TableCell colSpan={3} className="text-center text-gray-500 py-4">
          No items available. Add some {title.toLowerCase()} to get started.
        </TableCell>
      </TableRow>
    );

    return (
      <div className="bg-gray-100 overflow-hidden rounded-b-lg">
        <div className="relative">
          <div className="overflow-auto max-h-[300px]">
            <Table className="table-fixed w-full">
              <colgroup>
                <col className="w-[10%]" />
                <col className="w-[60%]" />
                <col className="w-[30%]" />
              </colgroup>
              <TableHeader className="sticky top-0 bg-gray-100 z-10">
                <TableRow>
                  <TableHead className="w-[10%]"></TableHead>
                  <TableHead className="w-[60%]">Item</TableHead>
                  <TableHead className="w-[30%] text-left">Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isAdding && (
                  <AddItemRow
                    onCancel={onAddingComplete}
                    onApprove={handleApproveAdd}
                  />
                )}
                {items.length > 0 ? memoizedItems : renderDefaultRow()}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
    );
  }
);

export default EditableList;
