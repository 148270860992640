import React from 'react';
import { Button } from "@/components/ui/button";
import { Star } from 'lucide-react';

const RatingButton = ({ value, label, rating, setRating }) => (
  <Button
    onClick={() => setRating(value)}
    className={`flex flex-col items-center justify-center p-1 sm:p-2 rounded-lg w-12 sm:w-16 h-16 sm:h-20 transition-colors ${
      rating === value
        ? 'bg-blue-200 border-2 border-blue-500 hover:bg-blue-200'
        : 'bg-white border border-gray-200 hover:bg-blue-100'
    }`}
  >
    <Star className={`w-4 h-4 sm:w-6 sm:h-6 mb-1 ${rating >= value ? 'text-yellow-400 fill-yellow-400' : 'text-gray-300'}`} />
    <span className="text-[8px] sm:text-xs text-[#172B4D] text-center leading-tight">{label}</span>
  </Button>
);

export default RatingButton;