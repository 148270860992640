import React from "react";
import {
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { UserPlus } from "lucide-react";

const ConnectionRequestModal = ({ onAccept, onDeny, userName }) => {
  return (
    <AlertDialogContent className="max-w-md">
      <div className="flex flex-col items-center text-center p-4">
        <div className="w-20 h-20 mb-6 text-purple-600 bg-gray-50 rounded-full p-4 shadow-inner">
          <UserPlus className="w-full h-full" />
        </div>
        <AlertDialogHeader className="space-y-4 text-center">
          <AlertDialogTitle className="text-2xl font-semibold text-center">
            Connection Request
          </AlertDialogTitle>
          <AlertDialogDescription className="text-gray-600 text-lg leading-relaxed text-center">
            {userName} would like to connect with you. Would you like to accept
            and join your networks together?
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter className="flex w-full gap-3 mt-8">
          <Button
            variant="outline"
            onClick={(e) => {
              e.stopPropagation();
              onDeny();
            }}
            className="flex-1 text-base py-6"
          >
            Decline
          </Button>
          <Button
            onClick={(e) => {
              e.stopPropagation();
              onAccept();
            }}
            className="flex-1 bg-purple-600 hover:bg-purple-700 text-base py-6"
          >
            Accept
          </Button>
        </AlertDialogFooter>
      </div>
    </AlertDialogContent>
  );
};

export default ConnectionRequestModal;
