import React from 'react';
import { Button } from "@/components/ui/button";
import { Loader2, Check, Copy } from "lucide-react";
import { toast } from "sonner";

const ActionButtons = ({
  guestUserId,
  meetingId,
  hostId,
  onSpotlightGenerated,
}) => {
  const [isCopyingFeedbackLink, setIsCopyingFeedbackLink] = React.useState(false);
  const [isGeneratingSpotlight, setIsGeneratingSpotlight] = React.useState(false);
  const [isSpotlightGenerated, setIsSpotlightGenerated] = React.useState(false);

  const handleGetFeedbackLink = async () => {
    setIsCopyingFeedbackLink(true);
    try {
      const response = await fetch(`https://hook.eu1.make.com/7jh9h86wjl3qctj95lg8c7foi51toz10?user_id=${guestUserId}&meeting_id=${meetingId}`);
      
      if (!response.ok) {
        throw new Error('Failed to fetch feedback link');
      }

      const data = await response.json();
      const shortlink = data.shortlink;

      if (shortlink) {
        await navigator.clipboard.writeText(shortlink);
        toast.success("Feedback link copied to clipboard!");
      } else {
        throw new Error('Shortlink not found in response');
      }
    } catch (error) {
      console.error('Error getting feedback link:', error);
      toast.error("Failed to get feedback link. Please try again.");
    } finally {
      setIsCopyingFeedbackLink(false);
    }
  };

  const handleGenerateSpotlightPost = async () => {
    setIsGeneratingSpotlight(true);
    setIsSpotlightGenerated(false);

    try {
      const response = await fetch(
        "https://hook.eu1.make.com/ezrrij44n9cmd6ne9a9387r1scisk482",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meeting_id: meetingId,
            host_id: hostId,
            send_guest: false,
          }),
        }
      );

      if (response.ok) {
        setIsSpotlightGenerated(true);
        onSpotlightGenerated();
      } else {
        throw new Error("Unexpected response from server");
      }
    } catch (error) {
      console.error("Error generating spotlight post:", error);
      toast.error("Failed to generate spotlight post. Please try again.");
    } finally {
      setIsGeneratingSpotlight(false);
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <Button
        onClick={handleGetFeedbackLink}
        disabled={isCopyingFeedbackLink}
        className="bg-gray-900 text-white hover:bg-gray-800 w-full sm:w-auto flex items-center justify-center"
      >
        {isCopyingFeedbackLink ? (
          <Loader2 className="h-4 w-4 mr-2 animate-spin flex-shrink-0" />
        ) : (
          <Copy className="h-4 w-4 mr-2 flex-shrink-0" />
        )}
        Get Feedback Link
      </Button>
      <Button
        onClick={handleGenerateSpotlightPost}
        disabled={isGeneratingSpotlight}
        className="bg-gray-900 text-white hover:bg-gray-800 w-full sm:w-auto relative"
      >
        <span className="absolute inset-0 flex items-center justify-center">
          {isGeneratingSpotlight ? (
            <Loader2 className="h-5 w-5 text-white animate-spin" />
          ) : isSpotlightGenerated ? (
            <Check className="h-5 w-5 text-white animate-scale-check" />
          ) : null}
        </span>
        <span
          className={
            isGeneratingSpotlight || isSpotlightGenerated
              ? "invisible"
              : "visible"
          }
        >
          Generate Spotlight Post
        </span>
      </Button>
    </div>
  );
};

export default ActionButtons;
