import React, { useState } from "react";
import TalentMatchCard from "./TalentMatchCard";

const TalentMatchList = ({ matches }) => {
  const [expandedId, setExpandedId] = useState(null);

  if (!matches || matches.length === 0) {
    return <p className="text-center text-gray-600">No matches available.</p>;
  }

  const handleToggle = (index) => {
    setExpandedId(expandedId === index ? null : index);
  };

  return (
    <div className="space-y-4">
      {matches.map((match, index) => (
        <TalentMatchCard 
          key={index} 
          match={match} 
          isExpanded={expandedId === index}
          onToggle={() => handleToggle(index)}
        />
      ))}
    </div>
  );
};

export default TalentMatchList;
