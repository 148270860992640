import React from 'react';
import StatItem from '../atoms/StatItem';
import { Skeleton } from "@/components/ui/skeleton";

const StatSection = ({
  isStatsLoading,
  daysSinceRegistration,
  lastCallIn,
  linkedInUpdated,
}) => (
  <div className="flex flex-col space-y-2">
    <StatItem
      label="Registered since"
      value={
        isStatsLoading ? (
          <Skeleton className="h-4 w-20" />
        ) : daysSinceRegistration ? (
          `${daysSinceRegistration} days`
        ) : (
          "N/A"
        )
      }
    />
    <StatItem
      label="Last Loop-In"
      value={
        isStatsLoading ? (
          <Skeleton className="h-4 w-20" />
        ) : lastCallIn ? (
          `${lastCallIn} days ago`
        ) : (
          "N/A"
        )
      }
    />
    <StatItem
      label="LinkedIn updated"
      value={
        isStatsLoading ? (
          <Skeleton className="h-4 w-20" />
        ) : (
          linkedInUpdated || "N/A"
        )
      }
    />
  </div>
);

export default StatSection;