import React from "react";
import ProfileHeader from "../molecules/ProfileHeader";
import ProfileInfo from "../molecules/ProfileInfo";
import ContractibleList from "../atoms/ContractibleList";
import ProfileLinks from "../atoms/ProfileLinks";
import { Button } from "@/components/ui/button";
import { Pencil } from "lucide-react";

const ProfileDefault = ({ profile, onEditClick }) => {
  return (
    <div className="overflow-auto relative h-full">
      <Button
        variant="ghost"
        size="icon"
        onClick={onEditClick}
        className="absolute top-0 right-0 z-10"
      >
        <Pencil className="h-4 w-4" />
      </Button>
      <ProfileHeader profile={profile} />
      <ProfileInfo
        industry={profile.industry}
        location={profile.location}
        needLatest={profile.need_latest}
      />
      <div className="space-y-6">
        <ContractibleList title="Key Skills" items={profile.key_skills} />
        <ContractibleList title="Business Goals" items={profile.business_goals} />
        <ContractibleList title="Interests" items={profile.interests} />
        <ProfileLinks 
          websiteUrl={profile.website_url}
          calendarUrl={profile.calendar_booking_url}
        />
      </div>
    </div>
  );
};

export default ProfileDefault;