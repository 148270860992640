import React from "react";
import { Lightbulb, Bug } from "lucide-react";

const IdeaBugButton = () => {
  const handleClick = () => {
    window.open("https://voiceloop.canny.io", "_blank");
  };

  return (
    <button
      onClick={handleClick}
      className="fixed bottom-0 right-0 flex items-center bg-purple-600 text-white px-4 py-2 rounded-tl-lg"
    >
      <Lightbulb className="w-4 h-4 mr-2" />
      <Bug className="w-4 h-4 mr-2" />
      <span className="text-sm font-medium">Idea + Bugs</span>
    </button>
  );
};

export default IdeaBugButton;
