import React, { createContext, useContext, useState } from "react";

const MatchContext = createContext();

export const MatchProvider = ({ children, initialMatches = [], isExpandable = true }) => {
  const [matches, setMatches] = useState(initialMatches);
  const [expandedMatchId, setExpandedMatchId] = useState(null);
  const [collapsingMatchId, setCollapsingMatchId] = useState(null);
  const [expandingMatchId, setExpandingMatchId] = useState(null);

  const handleExpand = (matchId) => {
    if (!isExpandable || expandedMatchId === matchId) return;

    if (expandedMatchId !== null) {
      setCollapsingMatchId(expandedMatchId);
      setExpandedMatchId(null);

      setTimeout(() => {
        setCollapsingMatchId(null);
        setExpandingMatchId(matchId);
        setTimeout(() => {
          setExpandedMatchId(matchId);
          setExpandingMatchId(null);
        }, 300);
      }, 300);
    } else {
      setExpandingMatchId(matchId);
      setTimeout(() => {
        setExpandedMatchId(matchId);
        setExpandingMatchId(null);
      }, 300);
    }
  };

  const value = {
    matches,
    setMatches,
    expandedMatchId,
    collapsingMatchId,
    expandingMatchId,
    handleExpand,
    isExpandable,
  };

  return (
    <MatchContext.Provider value={value}>{children}</MatchContext.Provider>
  );
};

export const useMatch = () => {
  const context = useContext(MatchContext);
  if (!context) {
    throw new Error("useMatch must be used within a MatchProvider");
  }
  return context;
};