import React from 'react';

const InfoItem = ({ label, value }) => (
  <div>
    <p className="font-semibold text-sm sm:text-base">{label}:</p>
    <p className="text-sm sm:text-base">{value}</p>
  </div>
);

export default InfoItem;
