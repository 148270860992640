import React, { useState } from "react";
import MatchList from "../components/organisms/MatchList";
import { Skeleton } from "@/components/ui/skeleton";
import { useMatches, useFeedback, useMeetings } from "../integrations/supabase";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import { Button } from "@/components/ui/button";
import FeedbackDialog from "../components/organisms/FeedbackDialog";
import PostMeetingMandatoryFeedback from "../components/organisms/PostMeetingMandatoryFeedback";
import { MatchProvider } from "../contexts/MatchContext";

const MatchSkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="h-12 w-3/4" />
    <Skeleton className="h-32 w-full" />
    <Skeleton className="h-32 w-full" />
    <Skeleton className="h-32 w-full" />
  </div>
);

const Index = () => {
  const { session } = useSupabaseAuth();
  const { useGetMatches } = useMatches();
  const { useGetMeetings } = useMeetings();
  const {
    data: matches,
    isLoading: matchesLoading,
    error: matchesError,
  } = useGetMatches(session?.user?.id);
  const { data: guestMeetings } = useGetMeetings(session?.user?.id);

  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const [isMandatoryFeedbackOpen, setIsMandatoryFeedbackOpen] = useState(false);

  const isLoading = matchesLoading;
  const error = matchesError;

  const handleGiveFeedbackClick = () => {
    setIsFeedbackDialogOpen(true);
  };

  if (error) {
    console.error("Error in Index component:", error);
    return (
      <div className="bg-white rounded-lg shadow-md p-4 sm:p-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-red-500 mb-4 sm:mb-6">
          Error
        </h1>
        <p className="text-sm sm:text-base">{error.message}</p>
      </div>
    );
  }

  return (
    <div className="px-4 pb-4 sm:px-6 sm:pb-6">
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4 sm:mb-6">
        <h1 className="text-2xl sm:text-3xl font-bold text-blue-500 mb-2 sm:mb-0">
          Top Matches
        </h1>
        <Button
          onClick={handleGiveFeedbackClick}
          className="shiny-button font-semibold text-sm sm:text-base w-full sm:w-auto"
          variant="outline"
        >
          Give Feedback
        </Button>
      </div>
      {isLoading ? (
        <MatchSkeleton />
      ) : matches && matches.length > 0 ? (
        <MatchProvider initialMatches={matches}>
          <MatchList />
        </MatchProvider>
      ) : (
        <p className="text-sm sm:text-base">No matches found.</p>
      )}
      <FeedbackDialog
        isOpen={isFeedbackDialogOpen}
        onClose={() => setIsFeedbackDialogOpen(false)}
      />
      <PostMeetingMandatoryFeedback
        isOpen={isMandatoryFeedbackOpen}
        onClose={() => setIsMandatoryFeedbackOpen(false)}
        meetingId={guestMeetings?.[0]?.meeting_id}
        onFeedbackSubmit={() => setIsMandatoryFeedbackOpen(false)}
      />
    </div>
  );
};

export default Index;
