import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";
import { useEffect } from "react";

/*
### profiles

| name                    | type                     | format      | required |
|------------------------|--------------------------|-------------|----------|
| user_id                | uuid                     | uuid        | true     |
| name                   | text                     | text        | false    |
| image_url              | text                     | text        | false    |
| key_skills             | text[]                   | _text       | false    |
| industry               | text                     | text        | false    |
| business_goals         | text[]                   | _text       | false    |
| interests              | text[]                   | _text       | false    |
| location               | text                     | text        | false    |
| hobbies                | text[]                   | _text       | false    |
| career_stage           | text                     | text        | false    |
| preferred_communication| text                     | text        | false    |
| link_linkedin          | text                     | text        | false    |
| link_aaa               | text                     | text        | false    |
| created_at             | timestamp with time zone | timestamptz | false    |
| updated_at             | timestamp with time zone | timestamptz | false    |
| interest_hosting       | boolean                  | bool        | false    |
| interest_updates       | boolean                  | bool        | false    |
| interest_podcast       | boolean                  | bool        | false    |
| interest_spotlight     | boolean                  | bool        | false    |
| logo_url               | text                     | text        | false    |
| calendar_booking_url   | text                     | text        | false    |
| website_url            | text                     | text        | false    |
| user_edits             | boolean                  | bool        | false    |
| need_latest            | text                     | text        | false    |
| referral_code          | text                     | text        | false    |
| referred_by            | uuid                     | uuid        | false    |

Foreign Key Relationships:
- user_id references auth.users.id
- referred_by references auth.users.id

Additional Information:
- interest_hosting: Is the member interested in becoming a AAA host and get discovery meetings booked?
- interest_updates: Is the member interested in receiving updates about the matchmaker?
- interest_podcast: Is the member interested in getting interviewed for a podcast episode?
- interest_spotlight: Is the member interested in getting a spotlight on LinkedIn, which will then be promoted by our hosts?
- logo_url: AI Agency Logo
- website_url: For linktree websites, etc.
- need_latest: What the user is looking for right now
- referral_code: User's unique referral code
- referred_by: UUID of the user who referred this user
*/

export const useProfiles = () => {
  const queryClient = useQueryClient();

  const getUserProfile = async (userId) => {
    if (!userId) throw new Error("User ID is required");
    const { data, error } = await supabase
      .from("profiles")
      .select("*")
      .eq("user_id", userId)
      .single();
    if (error) throw error;
    return data;
  };

  const addProfile = async (newProfile) => {
    const { data, error } = await supabase
      .from("profiles")
      .insert([newProfile]);
    if (error) throw error;
    return data;
  };

  const updateProfile = async ({ user_id, updatedProfile }) => {
    const { data, error } = await supabase
      .from("profiles")
      .update(updatedProfile)
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const deleteProfile = async (user_id) => {
    const { data, error } = await supabase
      .from("profiles")
      .delete()
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const deleteProfileItems = async ({ user_id, listType, items }) => {
    const { data, error } = await supabase
      .from("profiles")
      .update({ [listType]: items, user_edits: true })
      .eq("user_id", user_id);
    if (error) throw error;
    return data;
  };

  const getUserProfileByEmail = async (email) => {
    if (!email) throw new Error("Email is required");

    // Step 1: Find the user_id from searchable_users view
    const { data: userData, error: userError } = await supabase
      .from("searchable_users")
      .select("user_id")
      .eq("email", email)
      .single();

    if (userError) throw userError;

    if (!userData) {
      throw new Error(`User not found for email: ${email}`);
    }

    // Step 2: Get the profile data using the user_id
    const { data: profileData, error: profileError } = await supabase
      .from("profiles")
      .select("user_id, name, image_url, link_linkedin, link_aaa")
      .eq("user_id", userData.user_id)
      .single();

    if (profileError) throw profileError;

    if (!profileData) {
      throw new Error(`Profile not found for user_id: ${userData.user_id}`);
    }

    return profileData;
  };

  const useGetUserProfile = (userId) => {
    const query = useQuery({
      queryKey: ["profile", userId],
      queryFn: () => getUserProfile(userId),
      enabled: !!userId,
      retry: false,
      staleTime: 1000 * 60 * 5, // 5 minutes
    });

    useEffect(() => {
      if (!userId) return;

      const subscription = supabase
        .channel(`profile:${userId}`)
        .on(
          "postgres_changes",
          {
            event: "INSERT",
            schema: "public",
            table: "profiles",
            filter: `user_id=eq.${userId}`,
          },
          (payload) => {
            queryClient.invalidateQueries(["profile", userId]);
          }
        )
        .on(
          "postgres_changes",
          {
            event: "UPDATE",
            schema: "public",
            table: "profiles",
            filter: `user_id=eq.${userId}`,
          },
          (payload) => {
            queryClient.invalidateQueries(["profile", userId]);
          }
        )
        .subscribe();

      return () => {
        supabase.removeChannel(subscription);
      };
    }, [userId, queryClient]);

    return query;
  };

  return {
    useGetUserProfile,
    useAddProfile: () =>
      useMutation({
        mutationFn: addProfile,
        onSuccess: () => queryClient.invalidateQueries(["profile"]),
        onError: (error) => {
          console.error("Error adding profile:", error);
        },
      }),
    useUpdateProfile: () =>
      useMutation({
        mutationFn: updateProfile,
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries(["profile", variables.user_id]),
        onError: (error) => {
          console.error("Error updating profile:", error);
        },
      }),
    useDeleteProfile: () =>
      useMutation({
        mutationFn: deleteProfile,
        onSuccess: (_, user_id) =>
          queryClient.invalidateQueries(["profile", user_id]),
        onError: (error) => {
          console.error("Error deleting profile:", error);
        },
      }),
    useDeleteProfileItems: () =>
      useMutation({
        mutationFn: deleteProfileItems,
        onSuccess: (_, variables) =>
          queryClient.invalidateQueries(["profile", variables.user_id]),
        onError: (error) => {
          console.error("Error deleting profile items:", error);
        },
      }),
    getUserProfileByEmail,
  };
};
