import React from "react";
import { motion } from "framer-motion";
import { Card } from "@/components/ui/card";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  MapPin,
  Zap,
  Briefcase,
  Users,
  MessageSquare,
  Globe,
  Heart,
  ChevronDown,
} from "lucide-react";
import ExpandedSectionItem from "../atoms/ExpandedSectionItem";

const TalentMatchCard = ({ match, isExpanded, onToggle }) => {
  const {
    matching_score,
    explanation,
    matched_skills,
    potential_collaboration,
    location,
    experience_level,
    communication_compatibility,
    geographical_synergy,
    shared_interests,
    matched_user,
  } = match;

  console.log("Match: ", match);

  const initials = matched_user?.name?.charAt(0) || "?";
  const fallbackAvatar = `https://api.dicebear.com/7.x/initials/svg?seed=${matched_user?.name}`;

  return (
    <Card
      className="rounded-lg shadow-lg border border-gray-100 bg-white hover:shadow-xl transition-all duration-300 cursor-pointer"
      onClick={onToggle}
    >
      <div className="p-6">
        {/* Header Section */}
        <div className="flex flex-col w-full sm:flex-row sm:items-start sm:justify-between">
          <div className="flex items-start space-x-4">
            <Avatar className="h-16 w-16 ring-2 ring-purple-100">
              <AvatarImage
                src={matched_user?.image_url || fallbackAvatar}
                alt={matched_user?.name}
              />
              <AvatarFallback className="bg-purple-50 text-purple-700">
                {initials}
              </AvatarFallback>
            </Avatar>
            <div>
              <h3 className="font-semibold text-xl text-gray-900">
                {matched_user?.name}
              </h3>
              <div className="flex items-center space-x-2 mt-1">
                <motion.div
                  animate={{ rotate: isExpanded ? 180 : 0 }}
                  transition={{ duration: 0.3 }}
                  className="flex items-center text-gray-400 hover:text-gray-600"
                >
                  <ChevronDown className="h-4 w-4" />
                </motion.div>
                <span className="text-sm font-medium ml-1">
                  {isExpanded ? "Show less" : "Show more"}
                </span>
              </div>
              {matched_user?.location && (
                <div className="flex items-center text-gray-500 mt-1">
                  <MapPin className="h-4 w-4 mr-1" />
                  <span className="text-sm">{location}</span>
                </div>
              )}
              {experience_level && (
                <div className="inline-block bg-gray-100 text-gray-700 text-xs font-medium px-2.5 py-1 rounded-full mt-2">
                  {experience_level}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4 sm:mt-0">
            <div className="bg-purple-50 text-purple-700 px-3 py-1.5 rounded-full text-sm font-medium">
              {matching_score}/10
            </div>
          </div>
        </div>

        {/* Expandable Section */}
        <motion.div
          className="overflow-hidden"
          initial={false}
          animate={{
            height: isExpanded ? "auto" : 0,
            opacity: isExpanded ? 1 : 0,
          }}
          transition={{ duration: 0.3 }}
        >
          <div className="mt-6 pt-4 border-t">
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
              <ExpandedSectionItem
                icon={<Zap />}
                title="Match Reason"
                content={explanation}
              />
              <ExpandedSectionItem
                icon={<Briefcase />}
                title="Potential Collaboration"
                content={potential_collaboration}
              />
              <ExpandedSectionItem
                icon={<Users />}
                title="Matched Skills"
                content={matched_skills}
              />
              <ExpandedSectionItem
                icon={<Heart />}
                title="Shared Interests"
                content={shared_interests}
              />
              <ExpandedSectionItem
                icon={<MessageSquare />}
                title="Communication Compatibility"
                content={communication_compatibility}
              />
              <ExpandedSectionItem
                icon={<Globe />}
                title="Geographical Synergy"
                content={geographical_synergy}
              />
            </div>
          </div>
        </motion.div>
      </div>
    </Card>
  );
};

export default TalentMatchCard;
