import React from "react";
import {
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
} from "../ui/alert-dialog";
import { Button } from "../ui/button";
import { MapPinIcon } from "lucide-react";

export const MEET_LOCATIONS = {
  VIRTUAL_MEETING: "Virtual Meeting",
  LINKEDIN_CHAT: "LinkedIn Chat",
  EMAIL_DM: "Email / DM",
  LIVE_IN_PERSON: "Live in Person",
};

const LocationSelectionModal = ({ onSelect, onClose, userName }) => {
  const [selected, setSelected] = React.useState("");

  const handleLocationSelect = (e, value) => {
    e.stopPropagation();
    setSelected(value);
  };

  const handleConfirm = (e) => {
    e.stopPropagation();
    onSelect(selected);
  };

  return (
    <AlertDialogContent className="max-w-md">
      <div className="flex flex-col items-center text-center p-4">
        <div className="w-20 h-20 mb-6 text-purple-600 bg-gray-50 rounded-full p-4 shadow-inner">
          <MapPinIcon className="w-full h-full" />
        </div>
        <AlertDialogHeader className="space-y-4">
          <AlertDialogTitle className="text-2xl font-semibold">
            Where did you meet {userName}?
          </AlertDialogTitle>
          <AlertDialogDescription className="text-gray-600 text-lg leading-relaxed">
            Select how you connected with {userName}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="grid grid-cols-2 gap-4 w-full my-8">
          {Object.entries(MEET_LOCATIONS).map(([key, value]) => (
            <button
              key={key}
              className={`p-4 rounded-xl border text-base ${
                selected === value
                  ? "border-purple-600 bg-purple-50 text-purple-600"
                  : "border-gray-200 hover:border-purple-200 hover:bg-purple-50"
              }`}
              onClick={(e) => handleLocationSelect(e, value)}
            >
              {value}
            </button>
          ))}
        </div>
        <AlertDialogFooter className="flex w-full gap-3">
          <Button
            variant="outline"
            onClick={onClose}
            className="flex-1 text-base py-6"
          >
            Maybe Later
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={!selected}
            className="flex-1 bg-purple-600 hover:bg-purple-700 text-base py-6"
          >
            Confirm Location
          </Button>
        </AlertDialogFooter>
      </div>
    </AlertDialogContent>
  );
};

export default LocationSelectionModal;