import React from 'react';
import { Card, CardContent } from "@/components/ui/card";
import ContractibleList from '../atoms/ContractibleList';

const GuestProfileCard = ({ keySkills, businessGoals, interests }) => {
  return (
    <Card className="h-full">
      <CardContent className="p-4">
        <h3 className="text-lg font-semibold mb-4">Profile:</h3>
        <div className="space-y-4">
          <ContractibleList title="Key Skills" items={keySkills} />
          <ContractibleList title="Business Goals" items={businessGoals} />
          <ContractibleList title="Interests" items={interests} />
        </div>
      </CardContent>
    </Card>
  );
};

export default GuestProfileCard;
