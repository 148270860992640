import React from 'react';
import { motion, AnimatePresence } from "framer-motion";
import MeetingCard from '../molecules/MeetingCard';

const MeetingList = ({ meetings, currentPage, direction }) => {
  const itemsPerPage = 1;
  const startIndex = (currentPage - 1) * itemsPerPage;
  const filteredMeetings = meetings ? meetings.filter(meeting => meeting.meeting_status !== 'done') : [];
  const currentMeeting = filteredMeetings[startIndex];

  return (
    <div className="flex-grow">
      <AnimatePresence mode="wait" initial={false}>
        <motion.div
          key={currentPage}
          initial={{ opacity: 0, x: direction * 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: direction * -50 }}
          transition={{ duration: 0.3 }}
        >
          {currentMeeting && <MeetingCard meeting={currentMeeting} />}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default MeetingList;