import React from 'react';

const StatItem = ({ label, value }) => (
  <div className="flex flex-col items-start">
    <p className="text-sm text-gray-500">{label}</p>
    <p className="font-semibold text-blue-600">{value}</p>
  </div>
);

export default StatItem;
