import React from "react";

const ExpandedSectionItem = ({ icon, title, content }) => (
  <div className="flex items-start space-x-2 border p-2 rounded">
    <div className="flex-shrink-0 w-6 h-6 flex items-center justify-center mt-1">
      {React.cloneElement(icon, { className: "w-5 h-5" })}
    </div>
    <div>
      <h4 className="font-semibold text-sm">{title}</h4>
      <p className="text-xs sm:text-sm text-gray-600">
        {Array.isArray(content)
          ? content.join(", ")
          : content || "Not specified"}
      </p>
    </div>
  </div>
);

export default ExpandedSectionItem;