import { useState, useEffect, createContext, useContext } from "react";
import { supabase } from "./index.js";
import { useQueryClient } from "@tanstack/react-query";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";

const SupabaseAuthContext = createContext();

export const SupabaseAuthProvider = ({ children }) => {
  return <SupabaseAuthProviderInner>{children}</SupabaseAuthProviderInner>;
};

export const SupabaseAuthProviderInner = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);
  const queryClient = useQueryClient();

  useEffect(() => {
    const getSession = async () => {
      setLoading(true);
      const {
        data: { session },
      } = await supabase.auth.getSession();
      setSession(session);
      setLoading(false);
    };

    getSession();

    const { data: authListener } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        setSession(session);
        queryClient.invalidateQueries("user");
        setLoading(false);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, [queryClient]);

  const logout = async () => {
    await supabase.auth.signOut();
    localStorage.removeItem("feedbackModalShown");
    setSession(null);
    queryClient.invalidateQueries("user");
  };

  const signInWithOTP = async (email) => {
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email,
        options: {
          shouldCreateUser: false,
        },
      });
      if (error) throw error;
      return { success: true, message: "Check your email for the login link!" };
    } catch (error) {
      console.error("Error signing in with OTP:", error);
      return { success: false, error: error.message };
    }
  };

  const signInWithPassword = async ({ email, password }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });
      if (error) throw error;
      return { success: true, data };
    } catch (error) {
      console.error("Error signing in with password:", error);
      return { success: false, error: error.message };
    }
  };

  const resetPassword = async (email) => {
    try {
      const { error } = await supabase.auth.resetPasswordForEmail(email, {
        redirectTo: import.meta.env.VITE_RESET_PASSWORD_REDIRECT_URL,
      });
      if (error) throw error;
      return {
        success: true,
        message: "Password reset email sent. Check your inbox.",
      };
    } catch (error) {
      console.error("Error resetting password:", error);
      return { success: false, error: error.message };
    }
  };

  const updateUser = async (newPassword) => {
    try {
      const { data, error } = await supabase.auth.updateUser({
        password: newPassword,
      });
      if (error) throw error;
      return { success: true, message: "Password updated successfully." };
    } catch (error) {
      console.error("Error updating password:", error);
      return { success: false, error: error.message };
    }
  };

  return (
    <SupabaseAuthContext.Provider
      value={{
        session,
        loading,
        logout,
        signInWithOTP,
        signInWithPassword,
        resetPassword,
        updateUser,
      }}
    >
      {children}
    </SupabaseAuthContext.Provider>
  );
};

export const useSupabaseAuth = () => {
  return useContext(SupabaseAuthContext);
};

export const SupabaseAuthUI = () => (
  <Auth
    supabaseClient={supabase}
    appearance={{ theme: ThemeSupa }}
    theme="default"
    providers={[]}
  />
);
