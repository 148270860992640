export const BADGE_STATES = {
  INITIAL: "initial",
  FOLLOW: "follow",
  FOLLOWING: "following",
  PENDING: "pending",
  CONNECTION_REQUEST: "connection_request",
};

const ConnectionBadgeContent = ({ state, connection_status, isReceiver }) => {
  switch (state) {
    case BADGE_STATES.INITIAL:
      return "Did you meet?";
    case BADGE_STATES.FOLLOW:
      return "Follow";
    case BADGE_STATES.FOLLOWING:
      return "Following";
    case BADGE_STATES.PENDING:
      if (isReceiver && connection_status === "pending") {
        return "⭐ Connection Request";
      }
      return "Pending";
    default:
      return "Did you meet?";
  }
};

export default ConnectionBadgeContent;
