import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "@/components/ui/dialog";
import { Button } from "@/components/ui/button";
import { Sparkles } from "lucide-react";

const SpotlightPostModal = ({ isOpen, onClose, guestName, hostEmail }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle className="text-center">Spotlight Post Generated</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center py-4">
          <Sparkles className="w-16 h-16 text-yellow-400 mb-4" />
          <p className="text-center mb-2">
            Spotlight Post for {guestName} was generated and sent to {hostEmail}
          </p>
          <p className="text-xs text-gray-500 italic text-center">
            The host will review and post the spotlight.
          </p>
        </div>
        <DialogFooter>
          <Button onClick={onClose} className="w-full">Close</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SpotlightPostModal;
