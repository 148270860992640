import { useQuery } from "@tanstack/react-query";
import { supabase } from "../integrations/supabase/supabase";

export const useReferrals = (userId) => {
  const getReferredUsersCount = async () => {
    const { count, error } = await supabase
      .from("profiles")
      .select("*", { count: "exact", head: true })
      .eq("referred_by", userId);

    if (error) throw error;
    return count || 0;
  };

  const getReferralCode = async () => {
    const { data, error } = await supabase
      .from("profiles")
      .select("referral_code")
      .eq("user_id", userId)
      .single();

    if (error) throw error;
    return data?.referral_code;
  };

  const referredUsersQuery = useQuery({
    queryKey: ["referredUsers", userId],
    queryFn: getReferredUsersCount,
    enabled: !!userId,
  });

  const referralCodeQuery = useQuery({
    queryKey: ["referralCode", userId],
    queryFn: getReferralCode,
    enabled: !!userId,
  });

  return {
    referredUsersCount: referredUsersQuery.data,
    referralCode: referralCodeQuery.data,
    isLoading: referredUsersQuery.isLoading || referralCodeQuery.isLoading,
    error: referredUsersQuery.error || referralCodeQuery.error,
  };
};