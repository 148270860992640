import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

/*
### linkedin

| name                 | type                     | format | required |
|----------------------|--------------------------|--------|----------|
| profile_url          | text                     | string | true     |
| status               | text                     | string | false    |
| full_name            | text                     | string | false    |
| profile_photo_url    | text                     | string | false    |
| current_job_title    | text                     | string | false    |
| current_company      | text                     | string | false    |
| company_linkedin     | text                     | string | false    |
| website              | text                     | string | false    |
| location             | text                     | string | false    |
| email                | text                     | string | false    |
| phone                | text                     | string | false    |
| about                | text                     | string | false    |
| skills               | text[]                   | array  | false    |
| experience           | text[]                   | array  | false    |
| education            | text[]                   | array  | false    |
| recommendations      | integer                  | number | true     |
| accomplishments      | text[]                   | array  | false    |
| interests            | text[]                   | array  | false    |
| created_at           | timestamp with time zone | string | false    |
| updated_at           | timestamp with time zone | string | false    |

*/

export const useLinkedin = () => {
  const queryClient = useQueryClient();

  const getLinkedinProfiles = async () => {
    const { data, error } = await supabase.from("linkedin").select("*");
    if (error) throw error;
    return data;
  };

  const getLinkedinProfileByUserId = async (userId) => {
    const { data, error } = await supabase
      .from("linkedin")
      .select("*")
      .eq("user_id", userId)
      .single();
    if (error) throw error;
    return data;
  };

  const addLinkedinProfile = async (newProfile) => {
    const { data, error } = await supabase
      .from("linkedin")
      .insert([newProfile]);
    if (error) throw error;
    return data;
  };

  const updateLinkedinProfile = async ({ profile_url, updatedProfile }) => {
    const { data, error } = await supabase
      .from("linkedin")
      .update(updatedProfile)
      .eq("profile_url", profile_url);
    if (error) throw error;
    return data;
  };

  const deleteLinkedinProfile = async (profile_url) => {
    const { data, error } = await supabase
      .from("linkedin")
      .delete()
      .eq("profile_url", profile_url);
    if (error) throw error;
    return data;
  };

  return {
    useGetLinkedinProfiles: () =>
      useQuery({ queryKey: ["linkedin"], queryFn: getLinkedinProfiles }),
    useGetLinkedinProfileByUserId: (userId) =>
      useQuery({
        queryKey: ["linkedin", userId],
        queryFn: () => getLinkedinProfileByUserId(userId),
        enabled: !!userId,
      }),
    useAddLinkedinProfile: () =>
      useMutation({
        mutationFn: addLinkedinProfile,
        onSuccess: () => queryClient.invalidateQueries(["linkedin"]),
      }),
    useUpdateLinkedinProfile: () =>
      useMutation({
        mutationFn: updateLinkedinProfile,
        onSuccess: () => queryClient.invalidateQueries(["linkedin"]),
      }),
    useDeleteLinkedinProfile: () =>
      useMutation({
        mutationFn: deleteLinkedinProfile,
        onSuccess: () => queryClient.invalidateQueries(["linkedin"]),
      }),
  };
};
