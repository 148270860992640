import React from "react";
import { MapPin } from "lucide-react";

const UserLocation = ({ location }) => (
  <div className="flex items-center mt-1 sm:mt-0 sm:ml-2 text-sm text-gray-600">
    <MapPin className="w-4 h-4 mr-1" />
    <span>{location}</span>
  </div>
);

export default UserLocation;
