import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "../supabase";

/*
### matches

| name                        | type                     | format        | required |
|-----------------------------|--------------------------|---------------|----------|
| match_id                    | uuid                     | uuid          | true     |
| user_id                     | uuid                     | uuid          | true     |
| matched_user_id             | uuid                     | uuid          | true     |
| matching_score              | numeric                  | numeric       | false    |
| explanation                 | text                     | text          | false    |
| complementary_skills        | text[]                   | _text         | false    |
| potential_collaboration     | text                     | text          | false    |
| shared_interests            | text[]                   | _text         | false    |
| geographical_synergy        | text                     | text          | false    |
| experience_level            | text                     | text          | false    |
| communication_compatibility | text                     | text          | false    |
| created_at                  | timestamp with time zone | timestamptz   | false    |
| updated_at                  | timestamp with time zone | timestamptz   | false    |
| meet_confirmed_at          | timestamp with time zone | timestamptz   | false    |
| match_confirmed_at         | timestamp with time zone | timestamptz   | false    |
| meet_location              | USER-DEFINED             | meet_location | false    |
| match_origin               | USER-DEFINED             | match_origin  | false    |

Foreign Key Relationships:
- user_id references profiles.user_id
- matched_user_id references profiles.user_id

Additional Information:
- match_id: Unique identifier for each match
- user_id: ID of the user for whom the match is created
- matched_user_id: ID of the user who is matched
- matching_score: Numerical score indicating the strength of the match
- explanation: Text explaining the rationale behind the match
- complementary_skills: Array of skills that complement between the matched users
- potential_collaboration: Description of potential collaborative opportunities
- shared_interests: Array of interests shared by the matched users
- geographical_synergy: Description of any geographical advantages or synergies
- experience_level: Indication of the experience level compatibility
- communication_compatibility: Description of communication style compatibility
- meet_confirmed_at: Timestamp when the users confirmed meeting each other
- match_confirmed_at: Timestamp when the users confirmed a successful match
- meet_location: User-defined type specifying the location for the match meeting
- match_origin: User-defined type indicating the source or origin of the match
*/

export const useMatches = () => {
  const queryClient = useQueryClient();

  const getMatches = async (userId) => {
    const { data, error } = await supabase
      .from("matches")
      .select(
        `
        *,
        matched_user:profiles!matches_matched_user_id_fkey(
          user_id,
          name,
          image_url,
          key_skills,
          industry,
          business_goals,
          interests,
          location,
          hobbies,
          career_stage,
          preferred_communication,
          link_linkedin,
          link_aaa,
          website_url,
          calendar_booking_url
        ),
        initiator:profiles!matches_user_id_fkey(
          user_id,
          name,
          image_url,
          key_skills,
          industry,
          business_goals,
          interests,
          location,
          hobbies,
          career_stage,
          preferred_communication,
          link_linkedin,
          link_aaa,
          website_url,
          calendar_booking_url
        )
      `
      )
      .or(
        `user_id.eq.${userId},and(matched_user_id.eq.${userId},connection_status.in.(pending,accepted))`
      )
      .order("matching_score", { ascending: false });

    if (error) throw error;

    return data.map((match) => {
      const isReverse = match.matched_user_id === userId;
      if (isReverse) {
        return {
          ...match,
          user_id: match.matched_user_id,
          matched_user_id: match.user_id,
          matched_user: match.initiator,
          isReceiver: true, // Manually set to true for reverse matches
        };
      }
      return {
        ...match,
        isReceiver: false, // Manually set to false for forward matches
      };
    });
  };

  const addMatch = async (newMatch) => {
    const { data, error } = await supabase.from("matches").insert([newMatch]);
    if (error) throw error;
    return data;
  };

  const updateMatch = async ({ match_id, updatedMatch }) => {
    const { data, error } = await supabase
      .from("matches")
      .update({
        ...updatedMatch,
        updated_at: new Date().toISOString(),
      })
      .eq("match_id", match_id);

    if (error) throw error;
    return data;
  };

  const deleteMatch = async (match_id) => {
    const { data, error } = await supabase
      .from("matches")
      .delete()
      .eq("match_id", match_id);
    if (error) throw error;
    return data;
  };

  return {
    useGetMatches: (userId) =>
      useQuery({
        queryKey: ["matches", userId],
        queryFn: () => getMatches(userId),
        enabled: !!userId,
      }),
    useUpdateMatch: () =>
      useMutation({
        mutationFn: updateMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
    useAddMatch: () =>
      useMutation({
        mutationFn: addMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
    useDeleteMatch: () =>
      useMutation({
        mutationFn: deleteMatch,
        onSuccess: () => queryClient.invalidateQueries(["matches"]),
      }),
  };
};
