import React, { useState } from "react";
import EditableList from "./EditableList";
import Creatable from "react-select/creatable";
import { toast } from "sonner";
import {
  ChevronDown,
  ChevronUp,
  Plus,
  Trash2,
  Link,
  ExternalLink,
  Globe,
  Calendar,
  Search,
} from "lucide-react";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";

const ProfileLists = ({
  profileData,
  isEditing,
  onUpdateProfile,
  onInputChange,
}) => {
  const [expandedSections, setExpandedSections] = useState({
    key_skills: false,
    business_goals: false,
    interests: false,
    website_url: false,
    need_latest: false,
  });

  const [selectedItems, setSelectedItems] = useState({
    key_skills: [],
    business_goals: [],
    interests: [],
    website_url: [],
  });

  const [addingSection, setAddingSection] = useState(null);

  const handleListUpdate = (section, updatedItems) => {
    onInputChange(section, updatedItems);
  };

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const handleSelectItem = (section, index) => {
    setSelectedItems((prev) => ({
      ...prev,
      [section]: prev[section].includes(index)
        ? prev[section].filter((i) => i !== index)
        : [...prev[section], index],
    }));
  };

  const handleAddItem = (section) => {
    setAddingSection(section);
  };

  const handleDeleteSelected = (section) => {
    const updatedItems = profileData[section].filter(
      (_, index) => !selectedItems[section].includes(index)
    );
    handleListUpdate(section, updatedItems);
    setSelectedItems((prev) => ({
      ...prev,
      [section]: [],
    }));
  };

  const renderSection = (title, section) => {
    if (section === "website_url") {
      return (
        <div key={section} className="mb-4">
          <Button
            variant="ghost"
            className={`w-full flex justify-between items-center py-2 px-4 bg-gray-100 ${
              expandedSections[section] ? "rounded-b-none" : "rounded-lg"
            }`}
            onClick={() => toggleSection(section)}
          >
            <span className="font-semibold">{title}</span>
            <div className="flex items-center space-x-2">
              {expandedSections[section] ? (
                <ChevronUp className="h-4 w-4" />
              ) : (
                <ChevronDown className="h-4 w-4" />
              )}
            </div>
          </Button>
          {expandedSections[section] && (
            <div className="bg-white p-4 rounded-b-lg border border-t-0 border-gray-200">
              <div className="space-y-4">
                <div className="flex items-center space-x-2">
                  <Calendar className="w-6 h-6 text-gray-400" />
                  <Input
                    placeholder="Enter Calendar booking URL"
                    className="flex-grow"
                    value={profileData.calendar_booking_url || ""}
                    onChange={(e) =>
                      onInputChange("calendar_booking_url", e.target.value)
                    }
                    disabled={!isEditing}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <Globe className="w-6 h-6 text-gray-400" />
                  <Input
                    placeholder="Enter Website/Linktree URL"
                    className="flex-grow"
                    value={profileData.website_url || ""}
                    onChange={(e) =>
                      onInputChange("website_url", e.target.value)
                    }
                    disabled={!isEditing}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }

    return (
      <div key={section} className="mb-4">
        <Button
          variant="ghost"
          className={`w-full flex justify-between items-center py-2 px-4 bg-gray-100 ${
            expandedSections[section] ? "rounded-b-none" : "rounded-lg"
          }`}
          onClick={() => toggleSection(section)}
        >
          <span className="font-semibold">{title}</span>
          <div className="flex items-center space-x-2">
            {expandedSections[section] && (
              <>
                <Button
                  variant="ghost"
                  size="sm"
                  className="p-0 h-6 w-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteSelected(section);
                  }}
                  disabled={!isEditing || selectedItems[section].length === 0}
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
                <Button
                  variant="ghost"
                  size="sm"
                  className="p-0 h-6 w-6"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAddItem(section);
                  }}
                  disabled={!isEditing}
                >
                  <Plus className="h-4 w-4" />
                </Button>
              </>
            )}
            {expandedSections[section] ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </div>
        </Button>
        {expandedSections[section] && (
          <EditableList
            title={title}
            items={profileData[section] || []}
            isEditing={isEditing}
            onUpdate={(updatedItems) => handleListUpdate(section, updatedItems)}
            selectedItems={selectedItems[section]}
            onSelectItem={(index) => handleSelectItem(section, index)}
            emptyMessage="No items here"
            isAdding={addingSection === section}
            onAddingComplete={() => setAddingSection(null)}
          />
        )}
      </div>
    );
  };

  const handleNeedLatestChange = (newValue) => {
    const updatedValue = newValue ? newValue.value : "";
    onInputChange("need_latest", updatedValue);
  };

  const renderNeedLatestSection = () => {
    const defaultOptions = [
      {
        value: "Sharing Ideas / Inspiration",
        label: "Sharing Ideas / Inspiration",
      },
      { value: "a Sales Partner", label: "a Sales Partner" },
      { value: "a Marketing Partner", label: "a Marketing Partner" },
      { value: "a Development Partner", label: "a Development Partner" },
      {
        value: "an Accountability Partner",
        label: "an Accountability Partner",
      },
      { value: "Project Feedback", label: "Project Feedback" },
      { value: "Roadblock Solutions", label: "Roadblock Solutions" },
    ];

    // Ensure we always have a valid value object
    const currentValue = profileData.need_latest
      ? { value: profileData.need_latest, label: profileData.need_latest }
      : null;

    return (
      <div key="need_latest" className="mb-4">
        <Button
          variant="ghost"
          className={`w-full flex justify-between items-center py-2 px-4 bg-gray-100 ${
            expandedSections.need_latest ? "rounded-b-none" : "rounded-lg"
          }`}
          onClick={() => toggleSection("need_latest")}
        >
          <span className="font-semibold">Current Need</span>
          <div className="flex items-center space-x-2">
            {expandedSections.need_latest ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </div>
        </Button>
        {expandedSections.need_latest && (
          <div className="bg-white rounded-b-lg border border-t-0 border-gray-200">
            <div className="flex items-center">
              <div className="flex-grow">
                <Creatable
                  isDisabled={!isEditing}
                  onChange={handleNeedLatestChange}
                  value={currentValue}
                  options={defaultOptions}
                  placeholder={`Current: ${
                    profileData.need_latest || "Select or create a need"
                  }`}
                  formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
                  menuPosition="fixed"
                  blurInputOnSelect
                  onBlurResetsInput={false}
                  isSearchable
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="space-y-6">
      <h2 className="text-lg font-semibold mb-4">Profile</h2>
      {renderNeedLatestSection()}
      {renderSection("Key Skills", "key_skills")}
      {renderSection("Business Goals", "business_goals")}
      {renderSection("Interests", "interests")}
      <h2 className="text-lg font-semibold mb-4">Communication</h2>
      {renderSection("Website URLs", "website_url")}
    </div>
  );
};

export default ProfileLists;
