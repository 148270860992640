import React from 'react';
import { X } from 'lucide-react';
import { Dialog, DialogContent } from "@/components/ui/dialog";

const MapPopup = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[90%] h-[80vh] sm:h-[90vh] p-0 max-w-full">
        <button
          onClick={onClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground z-50"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
        <div className="h-full w-full">
          <iframe 
            width="100%" 
            height="100%" 
            frameBorder="0" 
            allowFullScreen 
            allow="geolocation" 
            src="//umap.openstreetmap.fr/en/map/aaa-matchmaker-map_1122503?scaleControl=false&miniMap=false&scrollWheelZoom=true&zoomControl=true&editMode=disabled&moreControl=true&searchControl=null&tilelayersControl=false&embedControl=false&datalayersControl=false&onLoadPanel=none&captionBar=false&captionMenus=false&captionControl=false&locateControl=true&measureControl=false&editinosmControl=false&starControl=false"
          ></iframe>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default MapPopup;