import React from "react";
import { Button } from "@/components/ui/button";
import { ExternalLink, Linkedin, Calendar, Globe } from "lucide-react";
import { ensureAbsoluteUrl } from "@/lib/utils";

const MatchCardButtons = ({ matched_user }) => {
  const hasWebsite = !!matched_user.website_url;
  const hasCalendar = !!matched_user.calendar_booking_url;

  const renderButtons = () => {
    switch (true) {
      case hasWebsite && hasCalendar:
        return (
          <>
            <Button
              className="flex-1 flex items-center justify-center bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.website_url),
                  "_blank"
                )
              }
            >
              <Globe className="w-4 h-4 mr-2" />
              Website
            </Button>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.calendar_booking_url),
                  "_blank"
                )
              }
            >
              <Calendar className="w-4 h-4 mr-2" />
              Meet Me
            </Button>
          </>
        );
      case hasWebsite && !hasCalendar:
        return (
          <>
            <Button
              className="flex-1 flex items-center justify-center bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.website_url),
                  "_blank"
                )
              }
            >
              <Globe className="w-4 h-4 mr-2" />
              Website
            </Button>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.link_linkedin),
                  "_blank"
                )
              }
            >
              <Linkedin className="w-4 h-4 mr-2" />
              LinkedIn
            </Button>
          </>
        );
      case !hasWebsite && hasCalendar:
        return (
          <>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.link_linkedin),
                  "_blank"
                )
              }
            >
              <Linkedin className="w-4 h-4 mr-2" />
              LinkedIn
            </Button>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.calendar_booking_url),
                  "_blank"
                )
              }
            >
              <Calendar className="w-4 h-4 mr-2" />
              Meet Me
            </Button>
          </>
        );
      default:
        return (
          <>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(
                  ensureAbsoluteUrl(matched_user.link_linkedin),
                  "_blank"
                )
              }
            >
              <Linkedin className="w-4 h-4 mr-2" />
              LinkedIn
            </Button>
            <Button
              className="flex-1 bg-gray-900 text-white hover:bg-gray-800"
              onClick={() =>
                window.open(ensureAbsoluteUrl(matched_user.link_aaa), "_blank")
              }
            >
              <ExternalLink className="w-4 h-4 mr-2" />
              AAA Profile
            </Button>
          </>
        );
    }
  };

  return (
    <div className="mt-6 flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 w-full">
      {renderButtons()}
    </div>
  );
};

export default MatchCardButtons;
