import React, { createContext, useContext, useState, useEffect } from "react";
import { useMeetings } from "../integrations/supabase";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import { format, parseISO } from "date-fns";
import { useHostStatus } from "../integrations/supabase/hooks/useHostStatus";

const MeetingsContext = createContext();

export const formatDate = (dateString) => {
  return dateString
    ? format(parseISO(dateString), "MMMM d, yyyy 'at' h:mm a")
    : "Not given";
};

export const MeetingsProvider = ({ children }) => {
  const [scheduledMeetings, setScheduledMeetings] = useState([]);
  const [completedMeetings, setCompletedMeetings] = useState([]);
  const [noShowMeetings, setNoShowMeetings] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentMeeting, setCurrentMeeting] = useState(null);

  const { session } = useSupabaseAuth();
  const { useGetMeetingsAsHost, useUpdateMeeting } = useMeetings();
  const { useGetHostStatus } = useHostStatus();
  const { data: hostStatus } = useGetHostStatus(session?.user?.id);
  const { isAdmin } = hostStatus || {};

  const {
    data: scheduledData,
    isLoading: scheduledLoading,
    error: scheduledError,
  } = useGetMeetingsAsHost(session?.user?.id, "scheduled", isAdmin);
  const {
    data: completedData,
    isLoading: completedLoading,
    error: completedError,
  } = useGetMeetingsAsHost(session?.user?.id, "done", isAdmin);
  const {
    data: noShowData,
    isLoading: noShowLoading,
    error: noShowError,
  } = useGetMeetingsAsHost(session?.user?.id, "noshow", isAdmin);

  const updateMeetingMutation = useUpdateMeeting();

  useEffect(() => {
    if (scheduledData && completedData && noShowData) {
      setScheduledMeetings(scheduledData);
      setCompletedMeetings(completedData);
      setNoShowMeetings(noShowData);
      setIsLoading(false);

      const closestMeeting = scheduledData[0] || completedData[0] || noShowData[0];
      setCurrentMeeting(closestMeeting);
    }
  }, [scheduledData, completedData, noShowData]);

  useEffect(() => {
    if (scheduledError || completedError || noShowError) {
      setError(scheduledError || completedError || noShowError);
      setIsLoading(false);
    }
  }, [scheduledError, completedError, noShowError]);

  const switchCurrentMeeting = (meetingId) => {
    const newCurrentMeeting = [...scheduledMeetings, ...completedMeetings, ...noShowMeetings].find(
      (meeting) => meeting.meeting_id === meetingId
    );
    if (newCurrentMeeting) {
      setCurrentMeeting(newCurrentMeeting);
    }
  };

  const updateMeetingStatus = async (meetingId, newStatus) => {
    try {
      await updateMeetingMutation.mutateAsync({
        meeting_id: meetingId,
        updatedMeeting: { meeting_status: newStatus },
      });
      
      // Update the current meeting status locally
      setCurrentMeeting((prevMeeting) => {
        if (prevMeeting && prevMeeting.meeting_id === meetingId) {
          return { ...prevMeeting, meeting_status: newStatus };
        }
        return prevMeeting;
      });

      // Update the meeting lists
      const updateMeetingInList = (list) =>
        list.map((meeting) =>
          meeting.meeting_id === meetingId
            ? { ...meeting, meeting_status: newStatus }
            : meeting
        );

      setScheduledMeetings(updateMeetingInList);
      setCompletedMeetings(updateMeetingInList);
      setNoShowMeetings(updateMeetingInList);
    } catch (error) {
      console.error("Failed to update meeting status:", error);
      // Handle the error (e.g., show a toast notification)
    }
  };

  const value = {
    scheduledMeetings,
    completedMeetings,
    noShowMeetings,
    isLoading,
    error,
    currentMeeting,
    switchCurrentMeeting,
    updateMeetingStatus,
  };

  return (
    <MeetingsContext.Provider value={value}>
      {children}
    </MeetingsContext.Provider>
  );
};

export const useMeetingsContext = () => {
  const context = useContext(MeetingsContext);
  if (!context) {
    throw new Error(
      "useMeetingsContext must be used within a MeetingsProvider"
    );
  }
  return context;
};