import React from 'react';
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { MapPin, Briefcase, Search } from 'lucide-react';

const GuestHeader = ({ name, location, careerStage, imageUrl, needLatest }) => (
  <div className="flex flex-col">
    <div className="flex items-center">
      <Avatar className="h-16 w-16 mr-4">
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback>{name.charAt(0)}</AvatarFallback>
      </Avatar>
      <div>
        <h2 className="text-2xl font-semibold">{name}</h2>
        <div className="flex items-center text-gray-600">
          <MapPin className="h-4 w-4 mr-1" />
          <p>{location}</p>
        </div>
        <div className="flex items-center text-gray-600">
          <Briefcase className="h-4 w-4 mr-1" />
          <p>{careerStage}</p>
        </div>
      </div>
    </div>
    {needLatest && (
      <div className="flex items-center mt-2 text-gray-600">
        <Search className="h-4 w-4 mr-1" />
        <p>..is currently looking for: {needLatest}</p>
      </div>
    )}
  </div>
);

export default GuestHeader;