import { Button } from "@/components/ui/button";
import { Alert, AlertDescription } from "@/components/ui/alert";
import { Skeleton } from "@/components/ui/skeleton";
import { useMeetings } from "../integrations/supabase";
import { useToast } from "@/components/ui/use-toast";
import { useEffect, useState } from "react";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import FeedbackDialog from "../components/organisms/FeedbackDialog";
import { useSupabaseAuth } from "../integrations/supabase/auth";
import { useMeeting } from "../contexts/MeetingContext";
import MeetingList from "../components/organisms/MeetingList";

const MeetingSkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="h-48 w-full" />
    <Skeleton className="h-10 w-32" />
  </div>
);

const Meetings = () => {
  const [isFeedbackDialogOpen, setIsFeedbackDialogOpen] = useState(false);
  const { useGetMeetings } = useMeetings();
  const { session }  = useSupabaseAuth();
  const { data: meetings, isLoading, error } = useGetMeetings(session?.user?.id);
  const { toast } = useToast();
  const [currentPage, setCurrentPage] = useState(1);
  const [direction, setDirection] = useState(0);
  const { dispatch } = useMeeting();

  useEffect(() => {
    if (meetings && meetings.length > 0) {
      const upcomingMeeting = meetings.find(meeting => 
        meeting.guest_user_id === session?.user?.id && meeting.meeting_status !== 'done'
      );
      if (upcomingMeeting) {
        dispatch({ type: 'SET_MEETING_ID', payload: upcomingMeeting.meeting_id });
      }
    }
  }, [meetings, dispatch, session?.user]);

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: error.message,
        variant: "destructive",
        className: "absolute top-4 right-4",
      });
    }
  }, [error, toast]);

  const upcomingMeetings = meetings ? meetings.filter(meeting => 
    meeting.guest_user_id === session?.user?.id && meeting.meeting_status !== 'done'
  ) : [];
  const completedMeetings = meetings ? meetings.filter(meeting => 
    meeting.guest_user_id === session?.user?.id && meeting.meeting_status === 'done'
  ) : [];
  const totalPages = upcomingMeetings.length;

  const handlePageChange = (newPage) => {
    setDirection(newPage > currentPage ? 1 : -1);
    setCurrentPage(newPage);
  };

  const handleFeedbackSubmit = (feedbackData) => {
    toast({
      title: "Feedback Submitted",
      description: "Thank you for your feedback!",
      className: "absolute top-4 right-4",
    });
  };

  const renderPaginationItems = () => {
    const items = [];
    const maxVisiblePages = 4;

    for (let i = 1; i <= Math.min(totalPages, maxVisiblePages); i++) {
      items.push(
        <PaginationItem key={i}>
          <PaginationLink
            onClick={() => handlePageChange(i)}
            isActive={currentPage === i}
            className="hover:bg-gray-200 transition-colors"
          >
            {i}
          </PaginationLink>
        </PaginationItem>
      );
    }

    return items;
  };

  return (
    <div className="h-full flex flex-col p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold text-blue-500">Upcoming Meetings</h1>
        {completedMeetings && completedMeetings.length > 0 && (
          <Button
            onClick={() => setIsFeedbackDialogOpen(true)}
            className="shiny-button font-semibold"
            variant="outline"
          >
            Give Feedback
          </Button>
        )}
      </div>
      {isLoading ? (
        <MeetingSkeleton />
      ) : upcomingMeetings && upcomingMeetings.length > 0 ? (
        <>
          <MeetingList meetings={upcomingMeetings} currentPage={currentPage} direction={direction} />
          {upcomingMeetings.length > 1 && (
            <div className="mt-6">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}
                      disabled={currentPage === 1}
                      className="hover:bg-gray-200 transition-colors"
                    />
                  </PaginationItem>
                  {renderPaginationItems()}
                  <PaginationItem>
                    <PaginationNext 
                      onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))}
                      disabled={currentPage === totalPages}
                      className="hover:bg-gray-200 transition-colors"
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </>
      ) : (
        <Alert variant="warning" className="mb-6 bg-yellow-100 border-yellow-400">
          <AlertDescription className="text-yellow-700">
            No upcoming meetings found for the current user.
          </AlertDescription>
        </Alert>
      )}
      <FeedbackDialog
        isOpen={isFeedbackDialogOpen}
        onClose={() => setIsFeedbackDialogOpen(false)}
        onSubmit={handleFeedbackSubmit}
      />
    </div>
  );
};

export default Meetings;