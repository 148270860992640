import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
import { Loader2 } from "lucide-react";
import InfiniteScroll from "react-infinite-scroll-component";

const HostListItem = ({ name, imageUrl, isActive, onClick, hostName, isAdmin }) => {
  const firstName = name ? name.split(" ")[0] : "Unknown";
  return (
    <div
      className={`flex items-center space-x-2 p-2 ${
        isActive ? "text-purple-600 bg-purple-100" : "text-black"
      } cursor-pointer hover:bg-gray-100 w-full`}
      onClick={onClick}
    >
      <Avatar className="w-8 h-8 flex-shrink-0">
        <AvatarImage src={imageUrl} alt={name} />
        <AvatarFallback>{firstName.charAt(0)}</AvatarFallback>
      </Avatar>
      <div className="flex flex-col">
        <span className="truncate">{firstName}</span>
        {isAdmin && hostName && (
          <span className="text-xs text-gray-400 truncate">{hostName}</span>
        )}
      </div>
    </div>
  );
};

const MeetingsList = ({
  meetings,
  currentMeeting,
  switchCurrentMeeting,
  emptyMessage,
  isAdmin,
  displayedMeetings,
  loadMoreMeetings,
}) => {
  if (meetings.length === 0) {
    return (
      <div className="flex flex-col items-center justify-center h-full text-gray-500">
        <p className="text-center text-sm">{emptyMessage}</p>
      </div>
    );
  }

  return (
    <InfiniteScroll
      dataLength={meetings.slice(0, displayedMeetings).length}
      next={loadMoreMeetings}
      hasMore={displayedMeetings < meetings.length}
      loader={<Loader2 className="w-4 h-4 animate-spin" />}
      scrollableTarget="meetingsContainer"
    >
      {meetings.slice(0, displayedMeetings).map((meeting) => (
        <HostListItem
          key={meeting.meeting_id}
          name={meeting.guest?.name || "Unknown"}
          imageUrl={meeting.guest?.image_url}
          isActive={meeting.meeting_id === currentMeeting?.meeting_id}
          onClick={() => switchCurrentMeeting(meeting.meeting_id)}
          hostName={meeting.host?.name || "Unknown Host"}
          isAdmin={isAdmin}
        />
      ))}
    </InfiniteScroll>
  );
};

export default MeetingsList;