import React, { useState } from 'react';
import { Button } from "@/components/ui/button";
import { ChevronDown, ChevronUp } from "lucide-react";

const ContractibleList = ({ items, initialDisplayCount = 5, title }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  // Check if items exist and have length
  if (!items || items.length === 0) {
    return null; // Don't render anything if there are no items
  }

  const displayedItems = isExpanded ? items : items.slice(0, initialDisplayCount);

  return (
    <div>
      {title && <h3 className="font-semibold mb-2">{title}</h3>}
      <div className="flex flex-wrap gap-2">
        {displayedItems.map((item, index) => (
          <span key={index} className="bg-gray-100 text-xs sm:text-sm rounded px-4 py-2.5">
            {item}
          </span>
        ))}
      </div>
      {items.length > initialDisplayCount && (
        <Button
          variant="ghost"
          size="sm"
          onClick={() => setIsExpanded(!isExpanded)}
          className="mt-2"
        >
          {isExpanded ? (
            <>
              Show Less <ChevronUp className="ml-2 h-4 w-4" />
            </>
          ) : (
            <>
              Show More <ChevronDown className="ml-2 h-4 w-4" />
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export default ContractibleList;