import React, { useState, useEffect } from "react";
import { Dialog, DialogContent } from "@/components/ui/dialog";
import { X } from "lucide-react";
import { useFeedback, useMeetings } from "../../integrations/supabase";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import { useMeeting } from "../../contexts/MeetingContext";
import { toast } from "sonner";
import FeedbackForm from "../molecules/FeedbackForm";
import ThankYouCard from "./ThankYouCard";

const FeedbackDialog = ({ isOpen, onClose }) => {
  const [rating, setRating] = useState(null);
  const [feedback, setFeedback] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submittedFeedbackId, setSubmittedFeedbackId] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [hostInfo, setHostInfo] = useState(null);
  const { useSubmitFeedback } = useFeedback();
  const { useGetHostInfo, useGetMeetings } = useMeetings();
  const submitFeedbackMutation = useSubmitFeedback();
  const { session } = useSupabaseAuth();
  const { dispatch } = useMeeting();

  const { data: meetings } = useGetMeetings(session?.user?.id);

  useEffect(() => {
    if (meetings && meetings.length > 0) {
      const latestMeeting = meetings[0];
      setMeetingId(latestMeeting.meeting_id);
      dispatch({ type: "SET_MEETING_ID", payload: latestMeeting.meeting_id });
    }
  }, [meetings, dispatch]);

  const {
    data: fetchedHostInfo,
    isLoading: isHostInfoLoading,
    error: hostInfoError,
  } = useGetHostInfo(meetingId);

  useEffect(() => {
    if (fetchedHostInfo) {
      setHostInfo(fetchedHostInfo);
    }
  }, [fetchedHostInfo]);

  const handleSubmit = async () => {
    if (!session?.user) {
      toast.error("You must be logged in to submit feedback.");
      return;
    }

    if (!meetingId) {
      toast.error("No meeting found. Unable to submit feedback.");
      return;
    }

    // Optimistically update the UI
    setIsSubmitted(true);
    const optimisticFeedbackId = Date.now().toString(); // Temporary ID
    setSubmittedFeedbackId(optimisticFeedbackId);

    try {
      const result = await submitFeedbackMutation.mutateAsync({
        meeting_id: meetingId,
        content: feedback,
        rating: rating,
      });

      if (result) {
        // Update with the actual feedback ID from the server
        setSubmittedFeedbackId(result.feedback_id);
        toast.success("Feedback submitted successfully!");
      } else {
        throw new Error("Failed to submit feedback. Please try again.");
      }
    } catch (error) {
      // Revert optimistic update
      setIsSubmitted(false);
      setSubmittedFeedbackId(null);
      toast.error("Failed to submit feedback. Please try again.");
      console.error("Error submitting feedback:", error);
    }
  };

  const handleClose = () => {
    setRating(null);
    setFeedback("");
    setIsSubmitted(false);
    setSubmittedFeedbackId(null);
    onClose();
  };

  if (hostInfoError) {
    console.error("Error fetching host info:", hostInfoError);
    toast.error("Failed to load host information. Please try again.");
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="sm:max-w-[600px] w-full p-4 sm:p-8 rounded-lg max-h-[90vh] overflow-y-auto min-w-[300px] sm:min-w-[400px]">
        <button
          onClick={handleClose}
          className="absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground"
        >
          <X className="h-4 w-4" />
          <span className="sr-only">Close</span>
        </button>
        {!isSubmitted ? (
          <FeedbackForm
            rating={rating}
            setRating={setRating}
            feedback={feedback}
            setFeedback={setFeedback}
            handleSubmit={handleSubmit}
            handleClose={handleClose}
            isSubmitting={submitFeedbackMutation.isLoading}
          />
        ) : isHostInfoLoading ? (
          <div>Loading host information...</div>
        ) : hostInfoError ? (
          <div>Error loading host information. Please try again.</div>
        ) : (
          <ThankYouCard
            rating={rating}
            hostName={hostInfo?.host_name || "your host"}
            hostImageUrl={hostInfo?.host_image_url}
            testimonialUrl={hostInfo?.host_testimonial_url}
            feedbackId={submittedFeedbackId}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default FeedbackDialog;
