import React from "react";
import { Briefcase, MapPin, Target } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Input } from "@/components/ui/input";

const IconWithText = ({
  icon,
  text,
  tooltip,
  isEditing,
  onInputChange,
  field,
}) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>
        <div className="flex items-center space-x-2 text-sm">
          {icon}
          {isEditing ? (
            <Input
              value={text}
              onChange={(e) => onInputChange(field, e.target.value)}
              className="h-8 py-1"
              placeholder={
                field === "industry"
                  ? "Industry"
                  : field === "location"
                  ? "Location"
                  : "What do you need?"
              }
            />
          ) : (
            <span>{text}</span>
          )}
        </div>
      </TooltipTrigger>
      <TooltipContent>
        <p>{tooltip}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

const ProfileInfo = ({
  industry,
  location,
  needLatest,
  isEditing,
  onInputChange,
}) => (
  <div className="space-y-2 mb-4">
    {(industry || isEditing) && (
      <IconWithText
        icon={<Briefcase className="h-4 w-4" />}
        text={industry}
        tooltip="Your professional industry"
        isEditing={isEditing}
        onInputChange={onInputChange}
        field="industry"
      />
    )}
    {(location || isEditing) && (
      <IconWithText
        icon={<MapPin className="h-4 w-4" />}
        text={location}
        tooltip="Your current location"
        isEditing={isEditing}
        onInputChange={onInputChange}
        field="location"
      />
    )}
    {needLatest && !isEditing && (
      <IconWithText
        icon={<Target className="h-4 w-4" />}
        text={needLatest}
        tooltip="What you currently need help with"
        isEditing={false}
        onInputChange={onInputChange}
        field="need_latest"
      />
    )}
  </div>
);

export default ProfileInfo;
