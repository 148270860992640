import React from "react";
import ExpandedSectionItem from "../atoms/ExpandedSectionItem";
import {
  Zap,
  Briefcase,
  Users,
  Heart,
  MessageSquare,
  Globe,
} from "lucide-react";

const ExpandedSection = (match) => {
  const {
    explanation,
    potential_collaboration,
    complementary_skills,
    shared_interests,
    communication_compatibility,
    geographical_synergy,
  } = match;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 sm:gap-4">
      <ExpandedSectionItem
        icon={<Zap />}
        title="Match Reason"
        content={explanation}
      />
      <ExpandedSectionItem
        icon={<Briefcase />}
        title="Potential Collaboration"
        content={potential_collaboration}
      />
      <ExpandedSectionItem
        icon={<Users />}
        title="Complementary Skills"
        content={complementary_skills?.join(", ")}
      />
      <ExpandedSectionItem
        icon={<Heart />}
        title="Shared Interests"
        content={shared_interests?.join(", ")}
      />
      <ExpandedSectionItem
        icon={<MessageSquare />}
        title="Communication Compatibility"
        content={communication_compatibility}
      />
      <ExpandedSectionItem
        icon={<Globe />}
        title="Geographical Synergy"
        content={geographical_synergy}
      />
    </div>
  );
};

export default ExpandedSection;
