import React, { Suspense } from "react";
import { useLocation } from "react-router-dom";
import ProfileCard from "../organisms/ProfileCard";
import { Skeleton } from "@/components/ui/skeleton";
import HostSidebar from "../organisms/HostSidebar";
import { MeetingsProvider } from "../../contexts/MeetingsContext";
import { motion, AnimatePresence } from "framer-motion";
import IdeaBugButton from "../atoms/IdeaBugButton";

const ProfileCardSkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="h-12 w-3/4" />
    <Skeleton className="h-4 w-full" />
    <Skeleton className="h-4 w-full" />
    <Skeleton className="h-4 w-2/3" />
  </div>
);

const ContentSkeleton = () => (
  <div className="space-y-4">
    <Skeleton className="h-8 w-3/4" />
    <Skeleton className="h-32 w-full" />
    <Skeleton className="h-32 w-full" />
    <Skeleton className="h-32 w-full" />
  </div>
);

const Layout = ({ children }) => {
  const location = useLocation();
  const isHostsRoute = location.pathname === "/hosts";

  return (
    <MeetingsProvider>
      <div className="bg-gray-100 flex flex-col min-h-screen overflow-x-hidden">
        <AnimatePresence mode="wait">
          <motion.div
            key={location.pathname}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="flex-grow p-4 sm:p-8"
          >
            <div className="flex flex-col lg:flex-row h-full space-y-4 lg:space-y-0 lg:space-x-8">
              {!isHostsRoute && (
                <div className="w-full lg:w-[30%] lg:min-w-[30%]">
                  <div className="bg-white rounded-lg shadow-lg h-full p-6">
                    <Suspense fallback={<ProfileCardSkeleton />}>
                      <ProfileCard />
                    </Suspense>
                  </div>
                </div>
              )}
              <div
                className={`w-full ${
                  isHostsRoute ? "lg:w-[80%]" : "lg:w-[70%]"
                }`}
              >
                <div className="bg-white rounded-lg shadow-md h-full p-6">
                  <Suspense fallback={<ContentSkeleton />}>{children}</Suspense>
                </div>
              </div>
              {isHostsRoute && (
                <div className="w-full lg:w-[20%] lg:self-start h-full">
                  <div className="sticky top-4 h-full">
                    <HostSidebar />
                  </div>
                </div>
              )}
            </div>
          </motion.div>
        </AnimatePresence>
        <IdeaBugButton />
      </div>
    </MeetingsProvider>
  );
};

export default Layout;
