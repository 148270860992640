import React from "react";
import ProfileHeader from "../molecules/ProfileHeader";
import ProfileInfo from "../molecules/ProfileInfo";
import ProfileLists from "../molecules/ProfileLists";
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";
import ImageUpload from "../molecules/ImageUpload";

const ProfileEdit = ({
  profile,
  onSaveClick,
  onInputChange,
  onUpdateProfile,
  newImagePreview,
  onImageUpload,
}) => {
  return (
    <div className="overflow-auto relative h-full">
      <Button
        variant="ghost"
        size="icon"
        onClick={onSaveClick}
        className="absolute top-0 right-0 z-10"
      >
        <Check className="h-4 w-4" />
      </Button>
      <ProfileHeader
        profile={profile}
        isEditing={true}
        onInputChange={onInputChange}
        newImagePreview={newImagePreview}
      >
        <ImageUpload isEditing={true} onImageUpload={onImageUpload} />
      </ProfileHeader>
      <ProfileInfo
        industry={profile.industry}
        location={profile.location}
        isEditing={true}
        onInputChange={onInputChange}
      />
      <ProfileLists
        profileData={profile}
        isEditing={true}
        onUpdateProfile={onUpdateProfile}
        onInputChange={onInputChange}
      />
    </div>
  );
};

export default ProfileEdit;
