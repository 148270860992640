import React, { createContext, useContext, useReducer } from 'react';

const MeetingContext = createContext();

const meetingReducer = (state, action) => {
  switch (action.type) {
    case 'SET_MEETING_ID':
      return { ...state, meetingId: action.payload };
    default:
      return state;
  }
};

export const MeetingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(meetingReducer, { meetingId: null });

  return (
    <MeetingContext.Provider value={{ state, dispatch }}>
      {children}
    </MeetingContext.Provider>
  );
};

export const useMeeting = () => {
  const context = useContext(MeetingContext);
  if (!context) {
    throw new Error('useMeeting must be used within a MeetingProvider');
  }
  return context;
};