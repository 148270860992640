import React, { useState } from "react";
import { useProfiles } from "../../integrations/supabase";
import { useSupabaseAuth } from "../../integrations/supabase/auth";
import ProfileDefault from "./ProfileDefault";
import ProfileEdit from "./ProfileEdit";
import ProfileCardSkeleton from "../molecules/ProfileCardSkeleton";
import { toast } from "sonner";
import axios from "axios";
import { isEqual } from "lodash";

const ProfileCard = () => {
  const { session } = useSupabaseAuth();
  const { useGetUserProfile, useUpdateProfile } = useProfiles();
  const {
    data: profile,
    isLoading,
    error,
  } = useGetUserProfile(session?.user?.id);
  const updateProfile = useUpdateProfile();
  const [isEditing, setIsEditing] = useState(false);
  const [editedProfile, setEditedProfile] = useState({});
  const [newImageFile, setNewImageFile] = useState(null);
  const [newImagePreview, setNewImagePreview] = useState(null);

  if (error) {
    console.error("Error in ProfileCard:", error);
    return (
      <div className="text-red-500">Error loading profile: {error.message}</div>
    );
  }

  if (isLoading || !profile) {
    return <ProfileCardSkeleton />;
  }

  const handleEditClick = () => {
    setIsEditing(true);
    setEditedProfile(profile);
  };

  const handleSaveClick = () => {
    if (!isEqual(profile, editedProfile) || newImageFile) {
      handleUpdateProfile(editedProfile);
    }
    setIsEditing(false);
  };

  const handleInputChange = (field, value) => {
    setEditedProfile((prev) => ({ ...prev, [field]: value }));
  };

  const handleUpdateProfile = async (updatedData) => {
    try {
      setEditedProfile(updatedData);
      await updateProfile.mutateAsync({
        user_id: session.user.id,
        updatedProfile: updatedData,
      });
      toast.success("Profile updated successfully");

      if (newImageFile) {
        const formData = new FormData();
        formData.append("image", newImageFile);
        formData.append("user_id", session.user.id);

        await axios.post(
          "https://hook.eu1.make.com/ra9cha2u8wd9xwz8bso28ao4sfmxuvn9",
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success("Profile photo uploaded successfully");
        setNewImageFile(null);
        setNewImagePreview(null);
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast.error("Failed to update profile. Please try again.");
    }
  };

  const handleImageUpload = (file) => {
    setNewImageFile(file);
    const reader = new FileReader();
    reader.onloadend = () => setNewImagePreview(reader.result);
    reader.readAsDataURL(file);
  };

  return isEditing ? (
    <ProfileEdit
      profile={editedProfile}
      onSaveClick={handleSaveClick}
      onInputChange={handleInputChange}
      onUpdateProfile={handleUpdateProfile}
      newImagePreview={newImagePreview}
      onImageUpload={handleImageUpload}
    />
  ) : (
    <ProfileDefault profile={profile} onEditClick={handleEditClick} />
  );
};

export default ProfileCard;
