import React from "react";
import HostHeader from "../molecules/HostHeader";
import MeetingInfo from "../molecules/MeetingInfo";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useMeetingsContext } from "../../contexts/MeetingsContext";
import { Copy, AlertCircle, CheckCircle2 } from "lucide-react";
import { useToast } from "@/components/ui/use-toast";

const NoShowGuestContent = ({
  name,
  location,
  careerStage,
  imageUrl,
  loopDate,
  meetingUrl,
  meetingId,
}) => {
  const { currentMeeting, updateMeetingStatus } = useMeetingsContext();
  const { toast } = useToast();

  const handleNoShow = (status) => {
    updateMeetingStatus(meetingId, status);
    toast({
      title: "Status Updated",
      description: `Meeting status set to ${status}`,
      duration: 3000,
    });
  };

  const handleGetRescheduleLink = async () => {
    try {
      const response = await fetch(`https://hook.eu1.make.com/p828vftz4rxg4j9mlh18wsc21su6hr66?meeting_id=${meetingId}`);
      const data = await response.json();
      
      // Show toast for successful payload receipt
      toast({
        title: "Success",
        description: "Reschedule link received successfully",
        duration: 3000,
        icon: <CheckCircle2 className="h-4 w-4 text-green-500" />,
      });
      
      if (data.reschedulelink) {
        await navigator.clipboard.writeText(data.reschedulelink);
        toast({
          title: "Copied",
          description: "Reschedule link copied to clipboard",
          duration: 3000,
          icon: <Copy className="h-4 w-4 text-green-500" />,
        });
      } else {
        throw new Error("Reschedule link not found in response");
      }
    } catch (error) {
      console.error("Error fetching reschedule link:", error);
      toast({
        title: "Error",
        description: "Failed to get reschedule link",
        variant: "destructive",
        duration: 3000,
        icon: <AlertCircle className="h-4 w-4 text-red-500" />,
      });
    }
  };

  return (
    <Card className="bg-white rounded-lg shadow-md max-h-full overflow-hidden flex flex-col">
      <HostHeader
        name={name}
        location={location}
        careerStage={careerStage}
        imageUrl={imageUrl}
      />
      <div className="p-4 sm:p-6 flex-grow overflow-auto">
        <MeetingInfo
          loopDate={loopDate}
          meetingUrl={meetingUrl}
          labelOverride={{ meetingDate: "Original Meeting Date" }}
        />
        {currentMeeting.meeting_status === "noshow" && (
          <div className="mt-4 flex flex-wrap gap-2">
            <Button 
              onClick={() => handleNoShow("noshow-guest")}
              variant="outline"
              className="flex items-center"
            >
              <AlertCircle className="mr-2 h-4 w-4" />
              Guest did not show up
            </Button>
            <Button 
              onClick={() => handleNoShow("noshow-host")}
              variant="outline"
              className="flex items-center"
            >
              <AlertCircle className="mr-2 h-4 w-4" />
              I missed the meeting
            </Button>
            <Button 
              onClick={() => handleNoShow("noshow-issue")}
              variant="outline"
              className="flex items-center"
            >
              <AlertCircle className="mr-2 h-4 w-4" />
              Transcript/Webhook Issue
            </Button>
          </div>
        )}
        {(currentMeeting.meeting_status === "noshow-guest" || 
          currentMeeting.meeting_status === "noshow-host" || 
          currentMeeting.meeting_status === "noshow-issue") && (
          <div className="mt-4 p-4 bg-gray-100 rounded-md">
            <h3 className="font-semibold text-lg mb-2">No Show Reason:</h3>
            <p>
              {currentMeeting.meeting_status === "noshow-guest"
                ? "Guest did not show up."
                : currentMeeting.meeting_status === "noshow-host"
                ? "Host did not show up."
                : "Transcript/Webhook Issue."}
            </p>
          </div>
        )}
        <div className="mt-4">
          <Button
            onClick={handleGetRescheduleLink}
            className="w-full flex items-center justify-center bg-blue-500 hover:bg-blue-600 text-white"
          >
            <Copy className="w-4 h-4 mr-2" />
            Get Reschedule Link
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default NoShowGuestContent;
