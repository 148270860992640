import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const ensureAbsoluteUrl = (url) => {
  if (!url) return "";
  if (url.match(/^https?:\/\//)) return url;
  return `https://${url}`;
};
